<!-- tabs component -->
<template>
  <div>
    <div class="flex align-items-center" style="justify-content: space-between">
      <el-tabs
        v-model="activeTab"
        class="base-tabs"
        style="overflow-x: auto"
        type="card"
        @tab-click="fetchData"
        @tab-remove="removeTab"
      >
        <el-tab-pane label="Seznam" name="list" />

        <el-tab-pane v-if="showNew" label="Nový" name="new" />

        <el-tab-pane
          v-for="tab in tabs"
          :key="String(tab.id)"
          :name="String(tab.id)"
          :label="String(tab.name)"
          closable
          lazy
        />
      </el-tabs>

      <base-button
        v-if="tabs.length > 0"
        tooltip="Zavřít všechny taby"
        icon="x"
        color="danger"
        type="border"
        size="small"
        @click="closeAllTabs"
      />
    </div>

    <keep-alive>
      <router-view
        v-if="isComponentInstance"
        v-show="hasPrivilegeList || hasPrivilegeNew || hasPrivilegeDetail"
        :key="$route.fullPath"
        :removed-tabs="removedTabs"
        :details-to-update="detailsToUpdate"
        @open-tab="openTab"
        @update-tab="updateTab"
        @remove-tab="removeTab"
        @update-removed-tabs="updateRemovedTabs"
        @update-detail="setUpdateDetail"
        @update-details-to-update="updateDetailsToUpdate"
        @open-tab-without-redirect="openTabWithoutRedirect"
      />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',

  props: {
    routePathKey: {
      type: String,
      default: ''
    },
    showNew: {
      type: Boolean,
      default: true
    },
    addItems: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      activeTab: '',

      privilegeList: '',
      privilegeNew: '',
      privilegeDetail: '',

      tabs: [],

      removedTabs: [],

      detailsToUpdate: [],
      updateDetail: {}
    };
  },

  computed: {
    isComponentInstance() {
      return this.$fnc.compareValues(
        this.routePathKey,
        this.$route.meta.path_key
      );
    },

    hasPrivilegeList() {
      return this.privilegeList === 'ok' && this.activeTab === 'list';
    },

    hasPrivilegeNew() {
      return this.privilegeNew === 'ok' && this.activeTab === 'new';
    },

    hasPrivilegeDetail() {
      return (
        this.privilegeDetail === 'ok' && this.$fnc.isNumber(this.activeTab)
      );
    }
  },

  activated() {
    if (this.$route.meta.tab && this.isComponentInstance) {
      this.activeTab = this.$route.meta.tab;
    }
  },

  methods: {
    // request na data danych sekci
    async fetchData(tab) {
      if (!this.isComponentInstance) {
        return false;
      }

      let routePath = '/';

      if (this.$fnc.isNumber(tab.name)) {
        routePath = {
          ...this.$getRoute(this.routePathKey, 'detail'),
          params: { id: tab.name, name: tab.label }
        };
      } else if (tab.name === 'new') {
        routePath = this.$getRoute(this.routePathKey, tab.name);
      } else {
        routePath = this.$getRoute(this.routePathKey).path;
      }

      try {
        const { resolved } = this.$router.resolve(routePath);
        const resolvedLinkPathKey = resolved.meta.path_key;

        if (
          this.$fnc.compareValues(resolvedLinkPathKey, this.routePathKey) &&
          !this.$fnc.compareValues(resolvedLinkPathKey, 'page_error')
        ) {
          await this.$router.push(routePath).catch(() => {});
        } else {
          await this.$router
            .push(this.$getRoute(this.routePathKey))
            .catch(() => {});
        }
      } catch (e) {
        this.$fnc.notificationAlert(
          'Došlo k chybě při přesměrování. Prosím kontaktujte správce. [code: 2202]',
          'error'
        );
      }

      return true;
    },

    // otevreni tabu detailu
    async openTab(tabsData) {
      if (!this.isComponentInstance) {
        return false;
      }

      if (!tabsData.id || tabsData.id == 0) {
        return false;
      }

      await this.fetchData({ name: tabsData.id, label: tabsData.name });
      this.updateTab(tabsData);
      //this.$router.push({name: this.routeName + ' - detail', path: this.routePath + '/detail', params: {id: tabsData.id, name: tabsData.name} }).catch(() => {})
    },

    async updateTab(tabsData) {
      if (!this.isComponentInstance) {
        return false;
      }

      // kontrola privileges sekci
      if (tabsData.tab) {
        if (
          tabsData.tab === 'list' &&
          this.updatePrivilegeList(tabsData.page_status) === false
        ) {
          return false;
        } else if (
          tabsData.tab === 'new' &&
          this.updatePrivilegeNew(tabsData.page_status) === false
        ) {
          return false;
        }
      } else {
        if (this.updatePrivilegeDetail(tabsData.page_status) === false) {
          return false;
        }

        if (
          this.$fnc.arrayFind(tabsData.status_list, 'status', 'error') ||
          this.$fnc.arrayFind(tabsData.status_list, 'status', 'item_not_found')
        ) {
          if (this.$route.params.id) {
            this.removeTab(this.$route.params.id);
          }

          return await this.openListSection();
        }
      }

      if (tabsData.tab) {
        this.activeTab = tabsData.tab;
        // return this.fetchData({ name: this.activeTab });
      }

      if (tabsData.id > 0) {
        // kontrola jestli je tab už otevren
        let tabItem = this.tabs.find((tab) => tab.id == tabsData.id);

        if (tabItem) {
          this.$set(tabItem, 'name', tabsData.name);
        } else {
          this.tabs.push({ ...tabsData });
        }

        this.$route.params.name = tabsData.name;

        // presmerovani na tab vybrane polozky
        this.activeTab = String(tabsData.id);
      } /* else {
          this.activeTab = 'list';
          return this.fetchData({ name: this.activeTab });
        } */

      this.$nextTick(() =>
        this.$fnc.changeDocumentTitle(this.$route, tabsData.name)
      );

      return true;
    },

    // zavreni tabu detailu
    async removeTab(tab_id) {
      if (!this.isComponentInstance) {
        return false;
      }

      let tabs = this.tabs;

      this.removedTabs.push(tab_id);
      this.tabs = tabs.filter((tab) => tab.id != tab_id);

      if (this.$route.params.id == tab_id) {
        await this.openListSection();
      }
    },

    // close all opened tabs
    async closeAllTabs() {
      if (!this.isComponentInstance) {
        return false;
      }

      this.tabs.forEach((tab) => {
        this.removedTabs.push(tab.id);
      });

      this.tabs = [];

      if (this.$fnc.isNumber(this.activeTab)) {
        await this.openListSection();
      }
    },

    // pouze otevre taby detailu (multiple option)
    openTabWithoutRedirect(tabsData) {
      if (!this.isComponentInstance) {
        return false;
      }

      if (tabsData.id > 0) {
        // kontrola jestli je tab už otevren
        let tabItem = this.tabs.find(
          (tab) => String(tab.id) === String(tabsData.id)
        );

        if (tabItem) {
          this.$set(tabItem, 'name', tabsData.name);
        } else {
          this.tabs.push({ ...tabsData });
        }
      }
    },

    // pokud se zavre detail, tak odebrat z array zavrenych tabu (id = ID detailu)
    updateRemovedTabs(id = 0) {
      if (!this.isComponentInstance) {
        return false;
      }

      if (id > 0) {
        this.removedTabs = this.removedTabs.filter(
          (i) => parseInt(i) !== parseInt(id)
        );
      }
    },

    // update detailu tabu kvuli switchum v tabulkach (item = object (id: required + column to update))
    setUpdateDetail(item = { id: 0 }) {
      if (!this.isComponentInstance) {
        return false;
      }

      if (item.id > 0) {
        this.detailsToUpdate.push(item);
      }
    },

    // ...odebere dany detail z array pro update detailu
    updateDetailsToUpdate(id = 0) {
      if (!this.isComponentInstance) {
        return false;
      }

      if (id > 0) {
        this.detailsToUpdate = this.detailsToUpdate.filter(
          (i) => parseInt(i.id) !== parseInt(id)
        );
      }
    },

    // kontrola privileges pro sekci Seznam
    updatePrivilegeList(privilege_status = '') {
      if (!this.isComponentInstance) {
        return false;
      }

      let output = true;

      if (privilege_status === 'no_privileges') {
        output = false;
      }

      if (privilege_status) {
        this.privilegeList = privilege_status;
      }

      return output;
    },

    // kontrola privileges sekce Novy
    updatePrivilegeNew(privilege_status = '') {
      if (!this.isComponentInstance) {
        return false;
      }

      let output = true;

      if (privilege_status === 'no_privileges') {
        output = false;
      }

      if (privilege_status) {
        this.privilegeNew = privilege_status;
      }

      return output;
    },

    // kontrola privileges sekce detailu
    updatePrivilegeDetail(privilege_status = '') {
      if (!this.isComponentInstance) {
        return false;
      }

      let output = true;

      if (!this.privilegeList && privilege_status === 'no_privileges') {
        output = false;
      }

      if (privilege_status) {
        this.privilegeDetail = privilege_status;
      }

      return output;
    },

    // presmerovani na tab Seznam
    async openListSection() {
      this.activeTab = 'list';
      await this.fetchData({ name: this.activeTab });
    }
  }
};
</script>
