<!-- table component -->
<template>
  <base-card
    :class="[addClassesCard, { 'mb-2': paddingBottom }]"
    :header="showHeader"
    :card="card"
  >
    <template slot="header">
      <!-- <h4>{{theader}}</h4> -->
      <div
        class="mx-1 inline-flex w-full align-items-center"
        v-if="showHeader || filterable"
      >
        <div class="text-lg inline-flex">
          <slot name="header" />
        </div>

        <vs-spacer />

        <slot name="header-items" />

        <el-input
          v-if="filterable && filterKeys.length > 0"
          v-model="searchQuery"
          size="mini"
          style="width: 200px;"
          placeholder="Vyhledávání"
        />

        <!-- <template v-if="pagination">
            <div class="mx-2 my-auto font-semibold text-sm">
              Počet řádků:
            </div>
            <vs-input-number
              v-model="itemsMax"
              size="medium"
              @input="itemsMax < 2 ? (itemsMax = 1) : ''"
            />
          </template> -->
      </div>
    </template>

    <div
      :class="{
        ['table-responsive-' + tableResponsiveSize]: tableResponsiveSize,
        'table-responsive': !tableResponsiveSize
      }"
      class="position-relative flex mb-0"
    >
      <component
        :is="scrollableBody ? 'perfect-scrollbar' : 'div'"
        class="position-relative w-100"
        :class="scrollClass"
      >
        <table
          :id="tableId"
          :key="tableId"
          class="
            table
            table-sm
            table-striped
            table-hover
            mx-auto
            font-sm
          "
          :class="[addClassesTable]"
        >
          <!-- border table-responsive-sm , {'table-scrollable' : scrollable} -->
          <thead :class="theadClass" :style="theadStyle">
            <tr>
              <slot name="thead-tr-top" />
            </tr>
            <tr>
              <template v-for="(field, index) in tableFields">
                <slot :name="'th-' + field.key">
                  <th
                    class="align-middle overflow-hidden"
                    :key="index + '-' + field.key"
                    :class="[
                      field._classes,
                      field._th_classes,
                      { 'th-sticky': scrollableBody }
                    ]"
                    :style="field._th_styles"
                  >
                    <!-- class="border" -->
                    <div class="white-space-pre-line">
                      <slot :name="'th-value-' + field.key">
                        {{ field.label }}
                      </slot>
                    </div>
                  </th>
                </slot>
              </template>
            </tr>
          </thead>

          <!-- <template v-if="tableItems.length > 0"> -->
          <tbody
            class="position-relative"
            :class="{ 'cursor-pointer': clickable }"
          >
            <!-- style="height: 200px !important; max-height: 200px !important; overflow: scroll;display: inline-block;" -->
            <!-- <perfect-scrollbar style="height: 200px" class="position-relative w-100"> -->
            <template v-for="(item, i) in tableItems">
              <tr
                :key="i + '-' + item.id"
                @click.stop="handleRowClick(item)"
                :class="item._classes"
              >
                <template v-for="(field, index) in fields.filter(Boolean)">
                  <slot :name="'td-' + field.key" :item="item">
                    <td
                      :key="index + '-' + field.key + '-' + i + '-' + item.id"
                      :class="[field._classes, field._td_classes]"
                      :style="field._styles"
                    >
                      <!-- {'text-right' : $fnc.isNumber(item[field.key])} -->
                      <slot :name="field.key" :item="item" :index="i">
                        {{ item[field.key] }}
                        <!-- {{item[field.key2]}} -->
                      </slot>
                    </td>
                  </slot>
                </template>

                <!-- mazani polozek tabulky -->
                <td
                  v-if="removableItems && moduleName"
                  class="text-center"
                  style="width: 30px"
                  @click.stop
                >
                  <slot name="action_remove" :item="item">
                    <base-button
                      color="danger"
                      size="sm"
                      @btn-click="removeItem(item.id)"
                    >
                      <font-awesome-icon
                        icon="trash-alt"
                        class="cursor-pointer"
                      />
                    </base-button>
                    <!-- <span class="cursor-pointer" @click.stop="removeItem(item.id)">SMAZAT</span> -->
                  </slot>
                </td>
              </tr>

              <tr v-if="showDetails" :key="'detail-' + i + '-' + item.id">
                <td :colspan="tableFields.length">
                  <slot name="detail" :item="item" :index="i" />
                </td>
              </tr>
            </template>

            <!-- vlastni posledni radek -->
            <tr
              class="cursor-auto font-weight-bold no-wrap"
              :key="'tbody-tr-bottom-' + tableId"
              @click.stop
            >
              <slot name="tbody-tr-bottom" />
            </tr>
            <!-- </perfect-scrollbar> -->
          </tbody>

          <!-- thead a tbody pod hl. tabulkou -->
          <thead class="position-relative">
            <tr class="cursor-auto" @click.stop>
              <slot name="thead-tr-bottom" />
            </tr>
          </thead>
          <tbody>
            <tr class="cursor-auto" @click.stop>
              <slot name="tbody-tr-bottom-2" />
            </tr>
          </tbody>
          <!-- </template> -->

          <!-- <tbody class="position-relative" v-else>
              <tr>
                <td :colspan="tableFields.length">
                  <div class="text-center my-5 cursor-auto font-3xl">
                    {{NO_DATA_TEXT}} <font-awesome-icon icon="times-circle" class="text-danger" />
                  </div>
                </td>
              </tr>
            </tbody> -->
        </table>
      </component>
    </div>

    <!-- slot under table -->
    <!-- <CRow class="w-100">
        <CCol class="mx-auto"> -->
    <slot name="table-bottom" />
    <!-- </CCol>
      </CRow> -->

    <base-row class="justify-content-center mt-3" v-if="pagination">
      <!-- && items.length > 10 -->
      <base-col col="auto" class="pr-1">
        <vs-pagination
          :total="totalPages"
          v-model="activePageCurrent"
          class="pagination-sm"
        ></vs-pagination>
      </base-col>

      <base-col col="auto" class="pl-1">
        <base-select
          :options="
            $fnc.createSelectOptions(itemsPerPageSelect, 'value', 'value')
          "
          :value.sync="itemsPerPageCurrent"
          :clearable="false"
          no-default-value
          size="small"
          style="width: 80px;"
        />
      </base-col>
    </base-row>

    <slot name="under-table" />
    <!-- {{activePage}} - {{itemsPerPage}} -->
  </base-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Sortable from 'sortablejs';
// import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

export default {
  name: 'base-table-2',

  components: {
    // Sortable,
    // PerfectScrollbar
  },

  props: {
    tableId: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    clickable: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    // schova hlavicku tabulky
    card: {
      type: Boolean,
      default: true
    },
    itemsPerPageSelector: {
      type: Boolean,
      default: false
    },
    /* itemsPerPage: {
      type: Number,
      default: 10
    }, */
    // zobrazi dalsi radek pod kazdym radkem
    showDetails: {
      type: Boolean,
      default: false
    },
    // deklarace trid pro tabulku
    addClassesTable: {
      type: String,
      default: ''
    },
    // deklarace trid pro header
    addClassesHeader: {
      type: String,
      default: ''
    },
    addStylesHeader: {
      type: String,
      default: ''
    },
    // deklarace trid pro card
    addClassesCard: {
      type: String,
      default: ''
    },
    addClassesBody: {
      type: String,
      default: ''
    },
    // modul // pro draggableItems, removableItems
    moduleName: {
      type: String,
      default: ''
    },
    // musi byt definovan, pokud je rodic znovupouzit (problem s vice stejnymi moduleName => musime pridat neco, co je odlisuje)
    moduleId: {
      type: [String, Number],
      default: '0'
    },
    // moznost prerazeni radku // musi byt definovan moduleName
    draggableItems: {
      type: Boolean,
      default: false
    },
    // moznost mazani radku // musi byt definovan moduleName
    removableItems: {
      type: Boolean,
      default: false
    },
    // scroll body tabulky
    scrollableBody: {
      type: Boolean,
      default: false
    },
    // scroll trida (nastaveni max height,...)
    scrollClass: {
      type: String,
      default: ''
    },
    tableResponsiveSize: {
      type: String,
      default: ''
    },
    theadClass: {
      type: String,
      default: ''
    },
    theadStyle: {
      type: String,
      default: ''
    },
    // vlastni definice poctu radku // plati pak tato promenna misto items.length
    paginationTotalRows: {
      type: [String, Number],
      default: 0
    },
    activePage: {
      type: [String, Number],
      default: 1
    },
    pageLimit: {
      type: [String, Number],
      default: 20
    },
    // true = enables table sorter
    sorter: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    filterKeys: {
      type: Array,
      default: () => ['name']
    },
    paddingBottom: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      activePageCurrent: 1,
      itemsPerPageCurrent: 20,
      itemsPerPageSelect: ['5', '10', '20', '50', '100', '500'],
      fieldRemove: { key: 'action_remove', label: '' },
      collapsed: false,
      sortFlag: '',
      sortKey: '',
      searchQuery: ''
    };
  },

  computed: {
    ...mapGetters(['NO_DATA_TEXT', 'USER_ID']),

    tableFields() {
      return this.removableItems && this.moduleName
        ? [...this.fields.filter(Boolean), this.fieldRemove]
        : this.fields.filter(Boolean);
    },

    tableItems() {
      let tableItems = this.items;

      if (this.filterable && this.filterKeys.length > 0 && this.searchQuery) {
        let filteredItems = [];

        this.filterKeys.forEach(key => {
          tableItems.filter(item => {
            if (this.$fnc.matchLowerCase(item[key], this.searchQuery)) {
              filteredItems.push(item);
            }
          });
        });

        tableItems = filteredItems;
      }

      if (this.pagination && this.paginationTotalRows == 0) {
        tableItems = tableItems
          .slice((this.activePageCurrent - 1) * this.itemsPerPageCurrent)
          .slice(0, this.itemsPerPageCurrent);
      }

      return tableItems;
    },

    totalPages() {
      if (this.pagination && this.paginationTotalRows > 0) {
        return Math.ceil(this.paginationTotalRows / this.itemsPerPageCurrent);
      }

      return Math.ceil(this.items.length / this.itemsPerPageCurrent);
    },

    activePageAndItemsPerPageWatcher() {
      return this.activePageCurrent + '|' + this.itemsPerPageCurrent;
    }
  },

  watch: {
    activePageAndItemsPerPageWatcher(val) {
      let temp = this.$fnc.splitText(val, '|');
      let aPage = temp[0]; // active page as index
      let iPage = temp[1]; // items per page

      //this.itemsOnPreviousPages = aPage * iPage

      let temp2 = { page: aPage, limit: iPage };

      this.$emit('update-list', temp2);
    }
  },

  mounted() {
    this.activePageCurrent = Number(this.activePage);
    this.itemsPerPageCurrent = Number(this.pageLimit);

    this.makeItemsDraggable();
  },

  updated() {
    this.makeItemsDraggable();
  },

  methods: {
    sortItems(field_key) {
      if (this.sortKey != field_key) {
        this.sortFlag = '';
      }

      this.sortFlag = this.sortFlag == 'asc' ? 'desc' : 'asc';
      this.sortKey = field_key;

      this.tableItems = [...this.items].sort(
        this.$fnc.dynamicSort((this.sortFlag == 'desc' ? '-' : '') + field_key)
      );
    },

    handleRowClick(item) {
      if (!this.clickable || window.getSelection().toString() !== '') {
        return false;
      }

      this.$emit('row-clicked', item);
    },

    async makeItemsDraggable() {
      // pro možnost uspořádat seznam drag n drop // musi byt definovan moduleName !!
      if (
        this.tableId &&
        this.draggableItems &&
        this.moduleName &&
        this.moduleName !== ''
      ) {
        let el = document.querySelector('#' + this.tableId + ' tbody');
        // console.log(el)
        if (el !== null) {
          Sortable.create(el, {
            animation: 200,
            onEnd: this.onEnd
          });
        }
      }
    },

    async onEnd(evt) {
      //this.sortTabs.option('disabled', false))
      let itemsOnPreviousPages =
        (this.activePageCurrent - 1) * this.itemsPerPageCurrent; // itemsOnPreviousPages kvuli pagination
      let oldIndex = evt.oldIndex + itemsOnPreviousPages;
      let newIndex = evt.newIndex + itemsOnPreviousPages;

      if (oldIndex === newIndex) return;

      let itemFrom = this.items[oldIndex].id;
      let itemTo = this.items[newIndex].id;

      // this.$emit('change-posindex', {item_from: itemFrom, item_to: itemTo})
      await this.$store.dispatch(this.moduleName + '-items-sorting', {
        item_from: itemFrom,
        item_to: itemTo
      });
      this.$emit('updated');
    },

    async removeItem(id) {
      if (this.$fnc.confirmAction()) {
        const isRemoved = await this.$store.dispatch(
          this.moduleName + '-remove',
          {
            id: id,
            hidden_by: this.USER_ID
          }
        );

        if (isRemoved) {
          this.$emit('removed-item', id);
        }
        /* if(data.status == 'ok' || data.status == 'saved') {
            this.$fnc.notificationSuccess(data.message)
            this.$emit("removed-item", id);
          } else {
            this.$fnc.notificationError(data.message)
          } */
      }
    }

    /* test(active_page, new_item_per_page) {
      this.itemsPerPage = new_item_per_page

      let temp = {page: active_page, limit: new_item_per_page}
      console.log(temp)
      this.$emit('update-list', temp)
    }, */
    /* paginator(items, current_page, per_page_items) {
      console.log(this.items)
      let page = this.activePage || 1,
      per_page = this.itemsPerPage || 10,
      offset = (page - 1) * per_page,

      paginatedItems = this.items.slice(offset).slice(0, this.itemsPerPage),
      total_pages = Math.ceil(this.items.length / per_page);

      this.totalPages = total_pages
      this.tableItems = paginatedItems

      return {
        page: page,
        per_page: per_page,
        pre_page: page - 1 ? page - 1 : null,
        next_page: (total_pages > page) ? page + 1 : null,
        total: this.items.length,
        total_pages: total_pages,
        data: paginatedItems
      };
    } */
  }
};
</script>

<style scoped>
td,
th {
  border: 0.01rem solid #d8dbe0;
  vertical-align: middle;
}
th {
  height: 30px;
}
table {
  /* line-height: 1.5; */
  width: calc(100% - 0.225rem); /* kvuli td th border */
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0;
}
/* scroll settings */
.th-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
.rotate {
  transform: rotate(180deg);
  transition: 0.2s;
}
/* @media (min-width: 992px) {
  .table-responsive {
    overflow: inherit;
  }
} */
.no-hover-effect:hover {
  background-color: white !important;
}
::v-deep select.page-select-height {
  height: 35.5px;
}
</style>
