import { extend } from 'vee-validate';
import { required, email, numeric, double, min_value } from 'vee-validate/dist/rules';

/* extend('number', value => {
    return value >= 0;
}) */

extend('numeric', {
    ...numeric,
    message: 'Toto pole musí obsahovat pouze čísla.'
});

extend('email', {
    ...email,
    message: 'Neplatný formát e-mailu.'
});

extend('required', {
    ...required,
    message: 'Toto pole je nutné vyplnit.'
});

extend('double', {
    ...double,
    message: 'Toto pole musí obsahovat pouze čísla.'
});

extend('min_value', {
    ...min_value,
    message: 'Toto pole je nutné vyplnit.'
});