<template>
  <el-checkbox
    v-model="inputValue"
    :name="name"
    :true-label="checkedValue"
    :false-label="uncheckedValue"
    :class="{ 'is-changed': isChanged }"
    class="my-auto"
  >
    <slot />
  </el-checkbox>
</template>

<script>
export default {
  name: 'base-checkbox',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    checkedValue: {
      type: [String, Number],
      default: 'Y'
    },
    uncheckedValue: {
      type: [String, Number],
      default: 'N'
    },
    isChanged: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('before-update', val);
        this.$emit('update:value', val);
      }
    }
  }
};
</script>

<style lang="scss">
.is-changed .el-checkbox__inner {
  border: 1px solid #ff9f43 !important;
  // border-radius: 10px;
  box-shadow: 0px 0px 0px 1px #ff9f43 inset;
}
</style>
