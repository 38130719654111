<template>
  <div>
    <el-radio
      v-for="option in options"
      :key="option.value"
      v-model="inputValue"
      :name="name"
      :label="String(option.value)"
      :class="{ 'is-changed': isChanged }"
    >
      {{ option.label }}
    </el-radio>
  </div>
</template>

<script>
export default {
  name: 'base-radio',

  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    isChanged: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    inputValue: {
      get() {
        return String(this.value);
      },
      set(val) {
        this.$emit('before-update', val);
        this.$emit('update:value', val);
      }
    }
  }
};
</script>

<style lang="scss">
.is-changed .el-radio__inner {
  border: 1.5px solid #ff9f43 !important;
}
</style>
