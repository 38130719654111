<template>
  <div class="vx-row">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseRow'
};
</script>
