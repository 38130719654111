<template>
  <div @click.stop>
    <b-form-checkbox
      :checked="inputValue"
      :value="checkedValue"
      :unchecked-value="uncheckedValue"
      :button-variant="variant"
      :class="addClasses"
      switch
      @change="updateValue"
    >
    </b-form-checkbox>
    <!-- <vs-switch :value="value === checkedValue" @click="updateValue" /> -->

    <!-- <div
      :class="{
        'bg-success': value.active == 'Y',
        'bg-danger': value.active == 'N'
      }"
      class="h-5 w-5 border-white border-2 border-solid rounded-full"
      @click.stop="changeUserActiveStatus(value)"
    /> -->
  </div>
</template>

<script>
export default {
  name: 'BaseSwitch',

  props: {
    value: {
      type: String,
      default: ''
    },
    checkedValue: {
      type: String,
      default: 'Y'
    },
    uncheckedValue: {
      type: String,
      default: 'N'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    addClasses: {
      type: String,
      default: ''
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
        this.$emit('updated', val);
      }
    }
  },

  methods: {
    updateValue() {
      let temp = this.uncheckedValue;

      if (this.$fnc.compareValues(this.value, this.uncheckedValue)) {
        temp = this.checkedValue;
      }

      this.$emit('update:value', temp);
      this.$emit('updated', temp);
    }
  }
};
</script>
