/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import Vue from 'vue';
import fnc from './../inc/funkce';
import themeConfig from '../../themeConfig';

const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  APP_SETTINGS_FETCH(state, payload) {
    if (!fnc.isObjectEmpty(payload)) {
      Object.keys(payload).forEach((key) => {
        Vue.set(state.appSettings, [key], payload[key]);
      });
    }
  },

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
    fnc.setLocalStorageAppSettings('reduceButton', val);
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val;
    fnc.setLocalStorageAppSettings('mainLayoutType', val);
  },
  UPDATE_ROUTER_TRANSITION(state, val) {
    state.routerTransition = val;
    fnc.setLocalStorageAppSettings('routerTransition', val);
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val;
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width;
  },
  UPDATE_NAVBAR_TYPE(state, val) {
    state.navbarType = val;
  },
  UPDATE_FOOTER_TYPE(state, val) {
    state.footerType = val;
    fnc.setLocalStorageAppSettings('footerType', val);
  },
  UPDATE_NAVBAR_COLOR(state, val) {
    state.navbarColor = val;
    fnc.setLocalStorageAppSettings('navbarColor', val);
  },
  UPDATE_HIDE_SCROLL(state, val) {
    state.hideScrollToTop = val;
    fnc.setLocalStorageAppSettings('hideScrollToTop', val);
  },
  UPDATE_NAVBAR_COLLAPSED(state, val) {
    state.navbarCollapsed = val;
  },
  UPDATE_HIDE_USER_PHOTOS(state, val) {
    state.hideUserPhotos = val;
    fnc.setLocalStorageAppSettings('hideUserPhotos', val);
  },

  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList['pages'].data.findIndex(
      (item) => item.url == payload.url
    );

    // update the main list
    state.navbarSearchAndPinList['pages'].data[index].is_bookmarked =
      payload.val;

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(
        state.navbarSearchAndPinList['pages'].data[index]
      );
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex(
        (item) => item.url == payload.url
      );

      // remove item using index
      state.starredPages.splice(index, 1);
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10);
    state.starredPages = list.concat(starredPagesMore);
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false;
    let lastItemInStarredLimited = state.starredPages[10];
    const starredPagesLimited = state.starredPages.slice(0, 10);
    state.starredPages = starredPagesLimited.concat(list);

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true;
    });

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited);
    }
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
    fnc.setLocalStorageAppSettings('themePrimaryColor', val);
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
    fnc.setLocalStorageAppSettings('theme', val);
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  UPDATE_WINDOW_SCROLL_Y(state, val) {
    state.scrollY = val;
  },

  RESET_LOCAL_SETTINGS(state) {
    state.themePrimaryColor = themeConfig.colors.primary;
    state.theme = themeConfig.theme;
    state.mainLayoutType = themeConfig.mainLayoutType;
    state.navbarType = themeConfig.navbarType;
    state.navbarColor = themeConfig.navbarColor;
    state.reduceButton = themeConfig.sidebarCollapsed;
    state.footerType = themeConfig.footerType;
    state.hideScrollToTop = themeConfig.hideScrollToTop;
    state.routerTransition = themeConfig.routerTransition;
    state.hideUserPhotos = themeConfig.hideUserPhotos;
  },

  // update main state vars
  set(state, [module, variable, value]) {
    if (value !== undefined) {
      if (module !== false) state[module][variable] = value;
      else state[variable] = value;
    }
  },

  // VLASTNI PRO BUTTONS
  /* TOGGLE_BUTTON_POPUP(state, val) { state.popupActive = val },
  DISPLAY_BUTTONS(state, val) { state.buttonData = val }, */

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state
  UPDATE_USER_INFO(state, payload) {
    if (!Vue.$fnc.isObject(payload)) return;

    let userInfo = state.AppActiveUser;

    for (const property of Object.keys(payload)) {
      if (payload[property] != null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        Vue.set(state.AppActiveUser, [property], payload[property]);
      }
    }

    // nacteni fotky podle id uziv
    if (payload.id) {
      userInfo['photoURL'] = Vue.$fnc.getUserImage(payload.id);
    }

    state.access_token = Vue.$auth.getAccessToken();
    state.isUserLogged = true;

    /* function getPhoto() {
      try {
        return require(`@/assets/images/portrait/small/avatar-s-${payload.id}.jpg`)
      } catch (error) {
        return require("@/assets/images/portrait/small/no-image.jpg")
      }
    } */
  },

  UPDATE_HIDDEN_RANGETYPES2(state, payload) {
    if (
      Object.keys(state.AppActiveUser.hiddenRangeTypes2).includes(
        payload.order_id
      )
    ) {
      state.AppActiveUser.hiddenRangeTypes2[payload.order_id].push(
        payload.range_type2_id
      );
    } else {
      state.AppActiveUser.hiddenRangeTypes2[payload.order_id] = [
        payload.range_type2_id
      ];
    }
  },

  UNHIDE_RANGETYPE2(state, payload) {
    state.AppActiveUser.hiddenRangeTypes2[payload.order_id] =
      state.AppActiveUser.hiddenRangeTypes2[payload.order_id].filter(
        (item) => !Vue.$fnc.compareValues(item, payload.range_type2_id)
      );
  },

  UNHIDE_ALL_RANGETYPES2(state) {
    state.AppActiveUser.hiddenRangeTypes2 = [];
  },

  UPDATE_UNSEEN_MESSAGES(state, payload) {
    state.AppActiveUser.unseenMessages = payload;
  },

  RESET_USER_DATA(state, payload) {
    state.AppActiveUser = {};
  },

  PROGRESS_START(state) {
    state.apiInProgress = true;
  },

  PROGRESS_STOP(state) {
    state.apiInProgress = false;
  }

  /* REMOVE_USER_DATA(state) {
    Object.keys(state.AppActiveUser).forEach(key => {
      Vue.set(state.AppActiveUser, key, false)
    })
  }, */

  /* UPDATE_LOGGED_USER_INFO(state, payload) {
    state.AppActiveUser.name = payload
  } */

  // Updates user info in state and localstorage
  /* UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    //console.log(payload)
    let userInfo = JSON.parse(localStorage.getItem("userInfo")) || state.AppActiveUser

    for (const property of Object.keys(payload)) {

      if (payload[property] != null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }


    }
    // Store data in localStorage
    localStorage.setItem("userInfo", JSON.stringify(userInfo))
  }, */
};

export default mutations;
