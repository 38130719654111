<!-- Reusable table component -->
<template>
  <div class="base-table">
    <!-- card title -->
    <base-card
      :class="{ 'mb-2': paddingBottom }"
      :header="showHeader"
      :card="card"
    >
      <template slot="header">
        <!-- <h4>{{theader}}</h4> -->
        <div
          class="mx-1 inline-flex w-full align-items-center"
          v-if="showHeader || filterable"
        >
          <div class="text-lg inline-flex">
            <slot name="header" />
          </div>

          <vs-spacer />

          <slot name="header-items" />

          <el-input
            v-if="filterable && filterKeys.length > 0"
            v-model="searchQuery"
            size="small"
            style="width: 200px;"
            class="lg:ml-2"
            placeholder="Vyhledávání"
          />

          <!-- <template v-if="pagination">
            <div class="mx-2 my-auto font-semibold text-sm">
              Počet řádků:
            </div>
            <vs-input-number
              v-model="itemsMax"
              size="medium"
              @input="itemsMax < 2 ? (itemsMax = 1) : ''"
            />
          </template> -->
        </div>
      </template>

      <!-- card body -->
      <slot name="over-table" />

      <div class="position-relative table-responsive d-flex">
        <component
          :is="scrollableBody ? 'perfect-scrollbar' : 'div'"
          class="position-relative w-100"
          :class="scrollClass"
        >
          <table
            :id="tableId"
            :key="tableId"
            :class="[addClassesTable]"
            class="
              base-table
              table-striped 
              table-hover
              table-sm
              font-xs
            "
          >
            <thead>
              <!-- custom thead row -->
              <tr
                v-if="$slots['thead-tr-top']"
                :class="{ 'position-sticky z-11': tableHeaderFixed }"
                class="border-bottom"
              >
                <slot name="thead-tr-top" />
              </tr>

              <!-- table header -->
              <tr
                :class="{ 'position-sticky z-10 bg-white': tableHeaderFixed }"
                :style="
                  (tableHeaderFixed ? 'top: ' + stickyPosTop + 'px;' : '') +
                    (scrollableBody ? 'border-collapse: separate;' : '')
                "
              >
                <template v-for="(field, index) in tableFields">
                  <slot :name="'th-' + field.key">
                    <th
                      :key="index + '-' + field.key"
                      :colspan="field.colspan"
                      :class="[
                        field._classes,
                        field._th_classes,
                        { 'th-sticky': scrollableBody }
                      ]"
                      :style="
                        field._th_styles +
                          ';' +
                          (scrollableBody ? 'top: ' + scrollPosTop + 'px;' : '')
                      "
                      class="align-middle overflow-hidden"
                    >
                      <slot :name="'th-value-' + field.key">
                        {{ field.label }}
                      </slot>
                    </th>
                  </slot>
                </template>
              </tr>
            </thead>

            <!-- table items -->
            <tbody
              v-if="tableItems.length > 0"
              class="table-items position-relative"
            >
              <template v-for="(item, i) in tableItems">
                <tr
                  :key="i + '-' + item.id"
                  :class="[
                    { 'cursor-pointer': clickable && !item.disabled },
                    { 'bg-lightgrey text-muted': item.disabled },
                    item._classes
                  ]"
                  :style="item._styles"
                  @click.stop="handleRowClick(item)"
                >
                  <template v-for="(field, index) in fieldsLocal">
                    <slot :name="'td-' + field.key" :item="item">
                      <td
                        :key="index + '-' + field.key + '-' + i + '-' + item.id"
                        :class="[
                          field._classes,
                          field._td_classes,
                          item['_classes_' + field.key]
                        ]"
                        :style="field._styles"
                      >
                        <slot :name="field.key" :item="item" :index="i">
                          <template v-if="field.formatter === 'currency'">{{
                            $fnc.currencyFormatter(item[field.key])
                          }}</template>
                          <template v-else-if="field.formatter === 'number'">{{
                            $fnc.numberFormatter(item[field.key])
                          }}</template>
                          <template v-else>{{ item[field.key] }}</template>
                        </slot>
                      </td>
                    </slot>
                  </template>

                  <!-- mazani polozek tabulky -->
                  <td
                    v-if="removableItems && moduleName"
                    class="text-center"
                    style="width: 30px"
                    @click.stop
                  >
                    <slot name="action_remove" :item="item">
                      <base-button
                        color="danger"
                        size="sm"
                        icon="trash-alt"
                        @btn-click="removeItem(item.id)"
                      />
                    </slot>
                  </td>
                </tr>

                <!-- row under each items -->
                <tr v-if="showDetails" :key="'detail-' + i + '-' + item.id">
                  <td :colspan="tableFields.length">
                    <slot name="detail" :item="item" :index="i" />
                  </td>
                </tr>
              </template>
            </tbody>

            <tbody class="position-relative" v-else>
              <tr>
                <td :colspan="tableFields.length">
                  <div class="text-center cursor-auto text-muted">
                    <template v-if="$slots['no-data-body']">
                      <slot name="no-data-body" />
                    </template>
                    <template v-else>{{ NO_DATA_TEXT }}</template>
                  </div>
                </td>
              </tr>
            </tbody>

            <!-- vlastni posledni radek -->
            <tbody v-if="$slots['tbody-tr-bottom']">
              <tr
                class="cursor-auto font-weight-bold no-wrap bg-white border-top"
                :key="'tbody-tr-bottom-' + tableId"
                @click.stop
              >
                <slot name="tbody-tr-bottom" />
              </tr>
            </tbody>

            <!-- thead a tbody pod hl. tabulkou -->
            <thead v-if="$slots['thead-tr-bottom']" class="position-relative">
              <tr class="cursor-auto" @click.stop>
                <slot name="thead-tr-bottom" />
              </tr>
            </thead>
            <tbody v-if="$slots['tbody-tr-bottom-2']">
              <tr class="cursor-auto" @click.stop>
                <slot name="tbody-tr-bottom-2" />
              </tr>
            </tbody>
          </table>
        </component>
      </div>

      <!-- slot under table -->
      <slot name="table-bottom" />

      <base-row class="justify-content-center mt-3" v-if="pagination">
        <!-- && items.length > 10 -->
        <base-col sm="auto" class="pr-1">
          <vs-pagination
            :total="totalPages"
            v-model="activePageCurrent"
            class="pagination-sm"
          ></vs-pagination>
        </base-col>

        <base-col sm="auto" class="pl-1">
          <base-select
            :options="
              $fnc.createSelectOptions(itemsPerPageSelect, 'value', 'value')
            "
            :value.sync="itemsPerPageCurrent"
            :clearable="false"
            no-default-value
            size="small"
            style="width: 80px;"
          />
        </base-col>
      </base-row>
    </base-card>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
// import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { mapGetters } from 'vuex';

export default {
  name: 'base-table-new',

  /* components: {
    PerfectScrollbar
  }, */

  props: {
    tableId: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    clickable: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    },
    itemsPerPageSelector: {
      type: Boolean,
      default: true
    },
    // vlastni definice poctu radku
    //  plati pak tato promenna misto items.length
    paginationTotalRows: {
      type: [String, Number],
      default: 0
    },
    pageLimit: {
      type: [String, Number],
      default: 0
    },
    activePage: {
      type: [String, Number],
      default: 1
    },
    // input vyhledavani tabulky
    tableFilter: {
      type: Boolean,
      default: false
    },
    // defaultne se vyhledava hodnota klice name zaznamu
    filterKeys: {
      type: Array,
      default: () => ['name']
    },
    // zobrazi hlavicku karty
    showHeader: {
      type: Boolean,
      default: false
    },
    // zobrazi dalsi radek pod kazdym radkem
    showDetails: {
      type: Boolean,
      default: false
    },
    // TODO: deprecated, dat pryc
    card: {
      type: Boolean,
      default: true
    },
    // deklarace trid pro card
    addClassesCard: {
      type: String,
      default: ''
    },
    // deklarace trid pro header
    addClassesHeader: {
      type: String,
      default: ''
    },
    // deklarace trid pro card body
    addClassesBody: {
      type: String,
      default: ''
    },
    // deklarace trid pro tabulku
    addClassesTable: {
      type: String,
      default: ''
    },
    // modul // pro draggableItems, removableItems
    moduleName: {
      type: String,
      default: ''
    },
    // musi byt definovan, pokud je rodic znovupouzit
    //  (problem s vice stejnymi moduleName => musime pridat neco, co je odlisuje)
    moduleId: {
      type: [String, Number],
      default: '0'
    },
    // moznost prerazeni radku // musi byt definovan moduleName
    draggableItems: {
      type: Boolean,
      default: false
    },
    // moznost mazani radku // musi byt definovan moduleName
    removableItems: {
      type: Boolean,
      default: false
    },
    // scroll body tabulky
    scrollableBody: {
      type: Boolean,
      default: false
    },
    // scroll trida (nastaveni max height,...)
    scrollClass: {
      type: String,
      default: ''
    },
    scrollPosTop: {
      type: [String, Number],
      default: 0
    },
    tableHeaderFixed: {
      type: Boolean,
      default: false
    },
    stickyPosTop: {
      type: [String, Number],
      default: 0
    }
    /* TODO: sorter: {
      type: Boolean,
      default: false
    }, */
  },

  data() {
    return {
      activePageCurrent: 1,
      itemsPerPageCurrent: 0,
      // prevent double req on pagination
      isUpdatingLimit: false,
      fieldRemove: { key: 'action_remove', label: '' },
      collapsed: false,
      searchQuery: ''
    };
  },

  computed: {
    ...mapGetters([
      'NO_DATA_TEXT',
      'ITEMS_PER_PAGE_SELECT',
      'ITEMS_PER_PAGE_CURRENT'
    ]),

    fieldsLocal() {
      return this.fields.filter(Boolean);
    },

    tableFields() {
      // if is removing items enabled
      if (this.removableItems && this.moduleName) {
        return [...this.fieldsLocal, this.fieldRemove];
      }

      return this.fieldsLocal;
    },

    tableItems() {
      let tableItems = this.items;

      if (this.tableFilter && this.filterKeys.length > 0 && this.searchQuery) {
        let filteredItems = [];

        this.filterKeys.forEach(key => {
          tableItems.filter(item => {
            if (
              !this.$fnc.arrayFind(filteredItems, 'id', item.id) &&
              this.$fnc.matchLowerCase(item[key], this.searchQuery)
            ) {
              filteredItems.push(item);
            }
          });
        });

        tableItems = filteredItems;
      }

      // custom FE pagination if not using PHP pagination
      if (
        this.pagination &&
        this.paginationTotalRows == 0 &&
        this.totalPages > 0
      ) {
        tableItems = tableItems
          .slice((this.activePageCurrent - 1) * this.itemsPerPageCurrent)
          .slice(0, this.itemsPerPageCurrent);
      }

      return tableItems;
    },

    totalPages() {
      if (this.itemsPerPageCurrent > 0) {
        if (this.pagination && this.paginationTotalRows > 0) {
          return Math.ceil(this.paginationTotalRows / this.itemsPerPageCurrent);
        }

        return Math.ceil(this.items.length / this.itemsPerPageCurrent);
      }

      return 0;
    }
  },

  mounted() {
    // set current page
    this.activePageCurrent = Number(this.activePage);

    // set current number of items per page
    if (this.pageLimit > 0) {
      this.itemsPerPageCurrent = Number(this.pageLimit);
    } else {
      this.itemsPerPageCurrent = Number(this.ITEMS_PER_PAGE_CURRENT);
    }

    this.makeItemsDraggable();
  },

  updated() {
    this.makeItemsDraggable();
  },

  methods: {
    // row item click
    handleRowClick(item) {
      if (
        !this.clickable ||
        item.disabled ||
        window.getSelection().toString() !== ''
      ) {
        return false;
      }

      this.$emit('row-clicked', item);
    },

    // update items based on pagination change
    async updateItems(data) {
      try {
        if (data.page) {
          this.activePageCurrent = data.page;
        }
        if (data.limit) {
          this.itemsPerPageCurrent = data.limit;
          // custom set current page
          //  to prevent double req
          this.isUpdatingLimit = true;
          if (this.activePageCurrent > this.totalPages) {
            this.activePageCurrent = this.totalPages;
          }
        }

        await this.$emit('update-list', {
          page: this.activePageCurrent,
          limit: this.itemsPerPageCurrent
        });
      } finally {
        this.isUpdatingLimit = false;
      }
    },

    // enable items drag option -->
    async makeItemsDraggable() {
      // pro možnost uspořádat seznam drag n drop
      //  musi byt definovan moduleName !!
      if (
        this.tableId &&
        this.draggableItems &&
        this.moduleName &&
        this.moduleName !== ''
      ) {
        let el = document.querySelector('#' + this.tableId + ' tbody');
        // console.log(el)
        if (el !== null) {
          Sortable.create(el, {
            animation: 200,
            onEnd: this.onEnd
          });
        }
      }
    },

    async onEnd(evt) {
      //this.sortTabs.option('disabled', false))
      // itemsOnPreviousPages kvuli pagination
      let itemsOnPreviousPages =
        (this.activePageCurrent - 1) * this.itemsPerPageCurrent;
      let oldIndex = evt.oldIndex + itemsOnPreviousPages;
      let newIndex = evt.newIndex + itemsOnPreviousPages;

      if (oldIndex === newIndex) return;

      let itemFrom = this.items[oldIndex].id;
      let itemTo = this.items[newIndex].id;

      await this.$store.dispatch(this.moduleName + '-items-sorting', {
        item_from: itemFrom,
        item_to: itemTo
      });
      this.$emit('updated');
    },
    // <--

    // remove item handler
    async removeItem(id) {
      if (this.$fnc.confirmAction()) {
        const data = await this.$store.dispatch(this.moduleName + '-remove', {
          id: id
        });

        let temp = data.status_list.find(item => item.status === 'removed');

        if (temp) {
          this.$emit('removed-item', id);
        }
      }
    }
  }
};
</script>

<style>
/* common table styles */
table {
  line-height: 1.5;
  width: 100%;
}
/* vertically align content center */
table.base-table td,
table.base-table th {
  vertical-align: middle !important;
}
/* table striped odd row color */
.table-striped tbody.table-items tr:nth-of-type(odd):not(:hover) {
  background-color: #eff4fa;
}
/* table hover set opacity for rows with defined background (aka hover effect) */
tbody.table-items tr[style^='background']:hover,
tbody.table-items tr:hover td[class^='bg-'] {
  opacity: 0.7;
}
/* scroll settings */
.th-sticky {
  position: sticky;
  position: -webkit-sticky;
  background-color: white;
  z-index: 1;
}
/* pagination styles */
.pagination {
  margin-bottom: 0 !important;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.68rem !important;
  margin-right: 5px;
  border-radius: 3px;
  box-shadow: none;
}
</style>
