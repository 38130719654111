<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />

    <BaseLoadingVue v-if="API_IN_PROGRESS" />
  </div>
</template>

<script>
import BaseLoadingVue from './components/base/BaseLoading.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseLoadingVue
  },
  data() {
    return {
      vueAppClasses: []
    };
  },
  computed: {
    ...mapGetters(['API_IN_PROGRESS'])
  },
  watch: {
    '$store.state.theme'(val) {
      this.setAppTheme();
    },
    '$vs.rtl'(val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr');
    },
    $route() {
      // solves modal overflow-y hidden issue
      document.body.style['overflow-y'] = '';
    }
  },
  mounted() {
    this.setLocalSettings();
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
  async created() {
    let dir = this.$vs.rtl ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);

    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    setLocalSettings() {
      this.setAppTheme();
      this.setAppPrimaryColor();
    },
    setAppPrimaryColor() {
      this.$vs.theme({ primary: this.$store.state.themePrimaryColor });
      let bodyStyles = document.body.style;
      bodyStyles.setProperty(
        '--primary-color',
        this.$store.state.themePrimaryColor
      );
    },
    setAppTheme() {
      const className = this.$store.state.theme;
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark');
        document.body.classList.add('theme-dark');
      } else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark');
        document.body.classList.add('theme-semi-dark');
      } else {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark');
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark');
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

      // Set --vh property
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY);
    }
  }
};
</script>
