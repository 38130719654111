const dict = {
    cs: {
      message: {
        hello: '{msg} světe!'
      },
      welcome: "Vítej"
    },
    en: {
      message: {
        hello: '{msg} world!'
      },
      welcome: "Welcome",
      routeNames: {
        user: 'User',
        intro: 'Intro'
      }
    },
    de: {
      message: {
        hello: '{msg} erde!'
      },
      welcome: "Willkommen",
      routeNames: {
        user: 'User',
        intro: 'Einführung'
      }
    },
  }

export default dict