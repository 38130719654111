<template>
  <form
    ref="base-pdf-generator"
    method="post"
    :action="getActionUrl"
    class="inline"
    target="_blank"
  >
    <input
      type="hidden"
      v-for="key in Object.keys(postParams)"
      :key="key"
      :name="key"
      :value="postParams[key]"
    />

    <slot>
      <base-button
        :size="size"
        :color="color"
        :disabled="disabled"
        :block="block"
        :add-classes="['h-100', addClasses]"
        :icon="icon"
        :type="type"
        @click="submitForm"
      >
        <template v-if="label">{{ label }}</template>
        <slot name="button-value" v-if="$scopedSlots['button-value']" />
      </base-button>
    </slot>
  </form>
</template>

<script>
export default {
  name: 'base-pdf-generator',

  props: {
    module: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    post: {
      type: Object,
      default: () => {}
    },
    get: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'filled'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    addClasses: {
      type: String,
      default: ''
    }
  },

  computed: {
    getActionUrl() {
      let getParams = { src: 'pdf' };

      if (this.$fnc.isObject(this.get)) {
        getParams = { ...getParams, ...this.get };
      }

      return this.$fnc.getUrl(this.module, this.action, getParams);
    },

    postParams() {
      if (this.$fnc.isObject(this.post)) {
        return this.post;
      }

      return {};
    }
  },

  methods: {
    async submitForm() {
      const form = this.$refs['base-pdf-generator']; //retrieve the form as a DOM element

      var input = document.createElement('input'); //prepare a new input DOM element
      input.setAttribute('name', 'access-token'); //set the param name
      input.setAttribute('value', this.$auth.getAccessToken()); //set the value
      input.setAttribute('type', 'hidden'); //set the type, like "hidden" or other

      form.appendChild(input); //append the input to the form

      await form.submit(); //send with added input

      form.removeChild(input); // remove input

      this.$emit('clicked');
    }
  }
};
</script>
