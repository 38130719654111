<template>
  <div>
    <div
      v-if="header && $scopedSlots['header']"
      :class="addClassesHeader"
      :style="addStylesHeader"
      class="base-card text-lg bg-header py-3 px-4 border-top border-right border-left font-semibold rounded-top"
    >
      <slot name="header" />
    </div>

    <div :class="{ 'bg-white border p-3': card }" v-if="$scopedSlots.default">
      <slot />
    </div>

    <!-- <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center">
      <vs-card class="card-style mb-0">
        <div slot="header" class="text-xl w-100">
          <slot name="header" />
        </div>

        <slot />
      </vs-card>
    </vs-col>
  </vs-row> -->
  </div>
</template>

<script>
export default {
  name: 'BaseCard',

  props: {
    header: {
      type: Boolean,
      default: true
    },
    card: {
      type: Boolean,
      default: true
    },
    addClassesHeader: {
      type: [String, Array, Object],
      default: ''
    },
    addStylesHeader: {
      type: String,
      default: ''
    }
  }
};
</script>
