<template>
  <div :class="'vx-col w-full ' + colComp">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseCol',

  props: {
    // TODO: amend this
    // this is setting width but keep it like this for now
    col: {
      type: [String, Number],
      default: '1/2'
    },
    sm: {
      type: String,
      default: ''
    },
    md: {
      type: String,
      default: ''
    },
    lg: {
      type: String,
      default: ''
    }
  },

  computed: {
    /* colComp() {
      let temp = ['xl:w-' + this.col];

      if (this.lg) {
        temp.push('lg:w-' + this.lg);
      }
      if (this.md) {
        temp.push('md:w-' + this.md);
      }
      if (this.sm) {
        temp.push('sm:w-' + this.sm);
      }

      return temp.join(' ');
    } */

    colComp() {
      let bp = 'xl';
      let temp = this.col;

      if (this.sm) {
        bp = 'sm';
        temp = this.sm;
      } else if (this.md) {
        bp = 'md';
        temp = this.md;
      } else if (this.lg) {
        bp = 'lg';
        temp = this.lg;
      }

      return bp + ':w-' + temp;
    }
  }
};
</script>
