/*=========================================================================================
  File Name: datastorage.js
  Description: actions requests to datastorage.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'datastorage';

const actions = {
  'remove-file': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'remove-file', payload);

    return data.success;
  }
};

export default {
  namespaced: true,
  actions
};
