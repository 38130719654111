<template>
  <div v-if="files.length > 0">
    <!-- Old approach: <form
      v-for="file in files"
      :key="file[fileIdKey]"
      :ref="'base-file-download-' + file[fileIdKey]"
      method="post"
      :action="$fnc.getUrl('datastorage', 'download-file')"
      target="_blank"
    >
      <input type="hidden" name="data_storage_id" :value="file[fileIdKey]" />
    </form>

    <button type="submit" class="btn-link" @click="submitForms">
      <template v-if="$slots.default">
        <slot />
      </template>
      <template v-else>Otevřít</template>
    </button> -->

    <template v-for="(file, index) in files">
      <div
        :key="file[fileIdKey] + '-' + index"
        :id="file[fileIdKey] + '-' + index"
        class="d-flex align-items-center my-2"
      >
        <form
          :ref="'base-file-download-' + file[fileIdKey]"
          method="post"
          :action="
            $fnc.getUrl('datastorage', 'download-file', {
              id: file[fileIdKey]
            })
          "
          target="_blank"
        />

        <div
          :id="'label-' + file[fileIdKey]"
          class="break-all"
          v-if="!$slots.default && hasLabel"
        >
          {{
            file.original_file_name
              ? file.original_file_name
              : file.generated_file_name
          }}
        </div>

        <button
          type="submit"
          class="btn-link"
          @click.prevent="submitForm(file[fileIdKey])"
        >
          <template v-if="$slots.default">
            <slot />
          </template>
          <template v-else>
            <feather-icon
              icon="DownloadIcon"
              class="w-4 h-4 d-flex my-auto"
              :class="{ 'ml-2': hasLabel }"
              title="Stáhnout soubor"
            />
            <!-- Otevřít -->
          </template>
        </button>
      </div>

      <hr class="my-0" v-if="index + 1 < files.length" :key="'hr-' + index" />
    </template>
  </div>

  <div v-else-if="hasDefaultText" class="text-sm text-muted text-center">
    Bez přílohy
  </div>
</template>

<script>
export default {
  name: 'BaseFileDownload',

  props: {
    files: {
      type: Array,
      default: () => []
    },
    fileIdKey: {
      type: String,
      default: 'id'
    },
    hasLabel: {
      type: Boolean,
      default: true
    },
    hasDefaultText: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    // download file
    async submitForm(file_id) {
      const form = this.$refs['base-file-download-' + file_id][0]; //retrieve the form as a DOM element

      var input = document.createElement('input'); //prepare a new input DOM element
      input.setAttribute('name', 'access-token'); //set the param name
      input.setAttribute('value', this.$auth.getAccessToken()); //set the value
      input.setAttribute('type', 'hidden'); //set the type, like "hidden" or other

      form.appendChild(input); //append the input to the form

      await form.submit(); //send with added input

      form.removeChild(input); // remove input
    }

    /* Old approach: // download all files
    submitForms() {
      this.files.forEach(async item => {
        const form = this.$refs[
          'base-file-download-' + item[this.fileIdKey]
        ][0]; //retrieve the form as a DOM element

        var input = document.createElement('input'); //prepare a new input DOM element
        input.setAttribute('name', 'access-token'); //set the param name
        input.setAttribute('value', this.$auth.getAccessToken()); //set the value
        input.setAttribute('type', 'hidden'); //set the type, like "hidden" or other

        form.appendChild(input); //append the input to the form

        await form.submit(); //send with added input

        form.removeChild(input); // remove input

        // document
        //   .getElementById('form-download-' + item[this.fileIdKey])
        //   .submit();
      });
    } */
  }
};
</script>

<style scoped>
.btn-link {
  background: none;
  border: none;
}
.btn-link:hover {
  text-decoration: underline;
}
</style>
