<template>
  <!-- file drag n drop backdrop -->
  <!-- <div
      v-if="isFileOver"
      class="position-absolute top-0 right-0 bottom-0 left-0 d-flex justify-content-center align-items-center text-2xl"
      style="background-color: rgba(0, 0, 0, 0.3)"
    >
      Přetáhněte soubor k nahrání
    </div> -->
  <drop
    @drop="handleDrop"
    @dragover="isFileOver = true"
    @dragleave="isFileOver = false"
    :class="{ 'drop-backdrop': isFileOver }"
  >
    <!-- :class="{ 'drop-backdrop': isFileOver }" -->
    <!-- file drag n drop backdrop, musi byt uvnitr drop elementu! -->
    <!-- TODO: <div
      class="position-absolute top-0 right-0 bottom-0 left-0"
      :class="{ 'drop-backdrop': isFileOver }"
    /> -->
    <!-- <div
      v-if="isFileOver"
      class="position-absolute top-0 right-0 bottom-0 left-0 text-2xl"
      style="background-color: rgba(0, 0, 0, 0.3);"
    >
      Přetáhněte soubor k nahrání
    </div> -->
    <slot />
  </drop>
</template>

<script>
import { Drop } from 'vue-drag-drop';

export default {
  name: 'BaseDrop',

  components: {
    Drop
  },

  data() {
    return {
      // file drag n drop
      isFileOver: false
    };
  },

  methods: {
    handleDrop(item, e) {
      // console.log(e.dataTransfer.files);
      e.preventDefault();
      this.$emit('drop', e.dataTransfer.files);
      this.isFileOver = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.drop-backdrop {
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
/* .drop-backdrop::before {
  content: 'tralala';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
} */
</style>
