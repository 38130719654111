<template>
  <div id="calendar-styles">
    <calendar-view
      locale="cs-CZ"
      :starting-day-of-week="1"
      :show-date="showDate"
      :enable-drag-drop="true"
      :events="calendarData"
      class="theme-default holiday-cz-traditional holiday-cz-official"
      @click-event="openEditEvent"
      @drop-on-date="dragStart"
    >
      <calendar-view-header
        slot="header"
        slot-scope="t"
        :header-props="t.headerProps"
        @input="setShowDate"
      />
    </calendar-view>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar';
import { mapGetters } from 'vuex';
require('vue-simple-calendar/static/css/default.css');
require('./holidays-cz.css');
// require('vue-simple-calendar/static/css/holidays-us.css');

export default {
  name: 'BaseCalendar2',

  components: {
    CalendarView,
    CalendarViewHeader
  },

  props: {
    calendarData: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      showDate: new Date()
      /* calendarData: [
        {
          id: 1,
          startDate: "2020-04-21 00:00:00",
          endDate: "2020-04-22 00:00:00",
          title: "Hello World!"
        },
        {
          id: 2,
          startDate: "2020-04-15 00:00:00",
          endDate: "2020-04-18 00:00:00",
          title: "Bye World!"
        }
      ] */
    };
  },

  computed: {
    ...mapGetters(['WORKFLOW_DEFAULT_TIME'])
  },

  methods: {
    setShowDate(d) {
      this.showDate = d;
    },

    openEditEvent(event) {
      this.$emit('open-edit', event);
    },

    dragStart(event, date) {
      const dateDiff = this.$fnc.dateDiffInDays(event.startDate, event.endDate);
      const newDateTo = date;

      date.setHours(
        event.startDate.getHours(),
        event.startDate.getMinutes(),
        event.startDate.getSeconds()
      );
      newDateTo.setDate(date.getDate() + dateDiff);
      newDateTo.setHours(
        event.endDate.getHours(),
        event.endDate.getMinutes(),
        event.endDate.getSeconds()
      );

      const dateFrom = this.$fnc.dateFormat(date);
      const dateTo =
        this.$fnc.dateFormat(newDateTo) + ' ' + this.WORKFLOW_DEFAULT_TIME;
      /* let numberOfDays = this.daysDiff(event.startDate, event.endDate)
      let days_in_month = this.daysInMonth(event.startDate.getMonth()+1, event.startDate.getFullYear())
      let newDayChecker = newDate.getDate() + numberOfDays
      let monthBetween = 0 */

      for (let i = 0; i < this.calendarData.length; i++) {
        // let start_date = new Date(this.calendarData[i].startDate)
        // let end_date = new Date(this.calendarData[i].endDate)

        /* if(days_in_month < newDayChecker) {
          console.log(newDayChecker)
          monthBetween = 1
        } */

        if (event.id == this.calendarData[i].id) {
          //this.calendarData[i].startDate = newDate
          //this.calendarData[i].endDate = end_date.setDate(newDate.getDate() + numberOfDays)
          //this.calendarData[i].endDate = end_date.setMonth(newDate.getMonth() + monthBetween)
          this.calendarData[i].startDate = dateFrom;
          this.calendarData[i].endDate = dateTo;
          break;
        }
      }
      // console.log(event);

      this.$emit('dragged', { id: event.id, new_date: dateTo });
    }

    /* daysDiff(dt1, dt2) {
      return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
    },
    monthsDiff(dt2, dt1) {

      var diff =(dt2.getTime() - dt1.getTime()) / 1000;
      diff /= (60 * 60 * 24 * 7 * 4);
      return Math.abs(Math.round(diff));
      
    },
    daysInMonth (month, year) {
        return new Date(year, month, 0).getDate();
    } */
  }
};
</script>

<style scoped>
#calendar-styles {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  color: #2c3e50;
  height: 70vh;
}
</style>
