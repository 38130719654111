<!-- Kompozice karet/tabu -->
<template>
  <div 
    tabindex="0" 
    @keydown.ctrl.87="removeTabShortcut" 
    @keydown.ctrl="numTabShortcut"
  >
    <!-- <el-card shadow="never"> -->
    <!-- <b-tabs>
      <b-tab 
        active 
        title="Seznam"
      >
        <component
          :is="componentListLoader"
          :items="items"
          :document-type="documentType"
          @show-detail="showDetail"
        />
      </b-tab>

      <b-tab 
        v-if="componentNew"
        active 
        title="Nový"
      >
        <component 
          :is="componentNewLoader" 
          @show-detail="showDetail" />
      </b-tab>

      <b-tab
        v-for="tab in tabs" 
        :key="`dyn-tab-${tab.id}`"  
        active
      >
        <template #title>
          <div 
            class="flex align-middle"
          >
            {{ tab.name }}
            <feather-icon 
              class="cursor-pointer my-auto ml-1 mt-1" 
              svg-classes="h-3 w-3" 
              icon="XIcon" 
              @click.stop="removeTab" />
          </div>
        </template>

        <component
          :is="componentDetailLoader"
          :item-id="tab.id"
          :pass-name="tab.name"
          :pass-doc-type="documentType"
          @show-detail="showDetail"
          @remove-tab="removeTab"
        />
      </b-tab>

    </b-tabs> -->

    <el-tabs 
      v-model="activeTab" 
      class="base-tabs"
      type="card" 
      @tab-remove="removeTab"
    >
      <!-- Pro komponentu seznam ..List.vue -->
      <el-tab-pane 
        id="list"
        label="Seznam" 
        name="list"
      >
        <component
          :is="componentListLoader"
          :items="items"
          :document-type="documentType"
          @show-detail="showDetail"
        />
      </el-tab-pane>

      <!-- Pro komponent nový ..New.vue -->
      <el-tab-pane 
        v-if="componentNew" 
        id="new"
        label="Nový" 
        name="new"
      >
        <component 
          :is="componentNewLoader" 
          @show-detail="showDetail"
        />
      </el-tab-pane>
        
      <!-- Pro detail komponentů ..Detail.vue -->
      <el-tab-pane 
        v-for="tab in tabs" 
        :key="`dyn-tab-${tab.id}`" 
        :name="tab.id + tab.name" 
        :label="tab.name" 
        lazy 
        closable
      >
        <component
          :is="componentDetailLoader"
          :item-id="tab.id"
          :pass-name="tab.name"
          :pass-doc-type="documentType"
          @show-detail="showDetail"
          @remove-tab="removeTab"
        />
      </el-tab-pane>
    </el-tabs>
    <!-- </el-card> -->
  </div>
</template>

<script>
import Sortable from "sortablejs";


export default {
  name: "BaseTabsOld",
  components: {
    Sortable
  },
  props: {
    componentList: {
      type: String,
      default: () => null,
    },
    componentNew: {
      type: String,
      default: () => null,
    },
    componentDetail: {
      type: String,
      default: () => null,
    },
    items: {
      type: Array,
      default: () => null,
    },
    documentType: {
      type: String,
      default: () => null,
    },
    // pro vytvoreni nove zakazky z kalkulace
    passTabs: {
      type: Object,
      default: () => null,
    }
  },
  data() {
      return {
        tabs: [],
        activeTab: "list",
        currentRoute: '',
        sortTabs: null
      }
  },
  computed: {
    componentListLoader() {
      return () => import(`@/views/${this.componentList}`);
    },
    componentNewLoader() {
      return () => import(`@/views/${this.componentNew}`);
    },
    componentDetailLoader() {
      return () => import(`@/views/${this.componentDetail}`);
    },
  },
  watch: {
    // pro vytvoreni novych polozek a jejich přesměrování do jiného komponentu
    passTabs(val) {
      //this.tabs.push(val)
      //this.activeTab = val.id
      this.showDetail(val.id, val.name)
      this.$emit("fetch")
    },
    /* '$store.state.themePrimaryColor'(val) {
      let el = document.querySelectorAll('.el-tabs__item')
      
      el.forEach(item => {
          item.style.color = val
          console.log(item)
      })
      //document.getElementsByClassName("el-tabs__item is-active")[8].style.color = val
      //console.log(document.getElementsByClassName("el-tabs__item is-active is-closable"))
    }, */
    /* '$route.fullPath'(val) {
      console.log(val)
      console.log(this.$router)
      this.currentRoute = val
      // console.log([to, next])
      // to(() => {
      //   console.log('entered')
        
      // })
      // next(() => {
      //   console.log('left')
      //   window.removeEventListener('keydown', this.removeTabShortcut)
      // })
    } */
  },
  mounted() {
    // pro možnost uspořádat taby
    let el = document.querySelector(".el-tabs__nav");
    //console.log(el)
    this.sortTabs = Sortable.create(el, {
      animation: 200,
      filter: "#tab-list, #tab-new",
      onEnd: this.onEnd,
      //direction: (e) => { console.log(e)}
      //onMove: this.testme,
     /*  
      group: {
        put: this.isSortable
      },
      sort: this.isSortable */
    });



    el.addEventListener('dragover', async (e) => {
      await this.sortTabs.option('disabled', true)
     // console.log(e)
      const listAndNewWidth = 175.2
      let test = e.target.offsetLeft < listAndNewWidth || e.target.id.includes('tab-list') || e.target.id.includes('tab-new')
      
      //if(e.target.id.includes('tab-list') || e.target.id.includes('tab-new')) this.sortTabs.option('disabled', true)
      
      this.$nextTick(() => {
        if(!test) this.sortTabs.option('disabled', false)
      })
      
    })

    el.addEventListener('wheel', (e) => {
      let num = 0 
      if(e.deltaX > 0) num = 1
      else num = - 1
      //return console.log(this.tabs.indexOf(this.tabs.find(item => item.id + item.name === this.activeTab)) + 1)
      let nextTab = this.tabs[this.tabs.indexOf(this.tabs.find(item => item.id + item.name === this.activeTab)) + num] 
      if(nextTab && this.activeTab) { this.activeTab = nextTab.id + nextTab.name }
    })

      //console.log(this)
      //this.$listeners('keydown', this.removeTabShortcut)
  },
  /* 
  destroyed() {
      console.log("left"),
      this.removeEventListener('keydown', this.removeTabShortcut)

  }, */
  methods: {
      onEnd(evt) {
          this.sortTabs.option('disabled', false)
          //if(evt.newIndex == 0 || evt.newIndex == 1) return console.log("haha")
          //We subtract 2 because the first items are always tab-list and tab-new
          let oldIndex = evt.oldIndex - 2;
          let newIndex = evt.newIndex - 2;
          //Update the tabs array to reflect the change that was just made
          this.tabs.splice(newIndex, 0, this.tabs.splice(oldIndex, 1)[0]);
          //console.log(evt.oldIndex)
        //Update the activeName to the item that was just moved
          this.activeTab = evt.item.id.replace('tab-', '');
          /* let temp = this.tabs[0]
          this.tabs[0] = this.tabs[1]
          this.tabs[1] = temp */
      },
      // zavreni tabu pomoci ctrl + w
      removeTabShortcut() {
        //if(e.ctrlKey && e.keyCode == 87) {
          if(this.activeTab !== 'list' && this.activeTab !== 'new') {
            this.removeTab(this.activeTab)
          }
            //console.log(e.keyCode)
            //e.preventDefault();
        //}
      },
      // otevreni tabu pomoci ctrl + poradi v tabu (0 pro Seznam)
      numTabShortcut(e) {
        if(e.code.includes('Digit') || e.code.includes('Numpad')) {

          if(parseInt(e.key) === 1) {
            this.activeTab = 'list'
          }
          if(parseInt(e.key) === 2) {
            this.activeTab = 'new'
          }

          this.tabs.forEach((item, index) => {
            
            if(parseInt(e.key) - 3 === index) {
              this.activeTab = item.id + item.name
            }
          })

        }
      },
      // funkce zobrazeni tabu s daty vybrane polozky v seznamu
      // new_data pro notifikaci a fetch => nastavit na true
      async showDetail(data_id, data_name, new_data) {
          if(new_data) {
            this.$vs.notify({
                title:'Info',
                text:'Data byla úspěšně uložena.',
                color:'success'
            })
            await this.$emit("fetch")
          }
          // kontrola jestli je tab už otevren
          let isTabOpened = this.tabs.find(tab => tab.id == data_id) ? true : false
          
          if ( isTabOpened ) {
              this.tabs.find(tab => tab.id == data_id).name = data_name
          } else {
              this.tabs.push({"id": data_id, "name": data_name})
          }

          // presmerovani na tab vybrane polozky
          this.activeTab = data_id + data_name
          
      },
      // funkce pro zavreni tabu
      removeTab(targetTab) {

        let tabs = this.tabs;
        let activeName = this.activeTab;

        if (activeName === targetTab) {
          tabs.forEach((tab, index) => {
            if (tab.id + tab.name === targetTab) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.id + nextTab.name;
              }
            }
          });
        }
        
        this.activeTab = activeName;
        this.tabs = tabs.filter(tab => tab.id + tab.name !== targetTab);

        if(!this.tabs.length) this.activeTab = 'list'

        this.$emit("fetch")
      }
  }
};
</script>

<style scoped>
  /* .vs-tabs-position-bottom .vs-tabs--ul, .vs-tabs-position-top .vs-tabs--ul {
    overflow: auto;
  } */
  /* ::-webkit-scrollbar { 
    display: none; 
  } */
</style>