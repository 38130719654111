var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.tooltip),expression:"tooltip",modifiers:{"right":true}}],class:[
    'shadow-none',
    _vm.addClasses,
    {
      btn: _vm.type === 'line',
      'border-0': _vm.type === 'line',
      'px-3': !_vm.icon,
      'px-0': _vm.icon && !_vm.$slots.default
    }
  ],attrs:{"size":_vm.size,"color":_vm.colorLocal,"icon-pack":"feather","icon":_vm.iconLocal,"type":_vm.type,"disabled":_vm.disabled,"to":_vm.to},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.buttonClick.apply(null, arguments)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }