<template>
  <div v-if="!$fnc.isArrayEmpty(files)">
    <!-- Old approach: <form
      v-for="file in files"
      :key="file[fileIdKey]"
      :ref="'base-file-opener-' + file[fileIdKey]"
      method="post"
      :action="$fnc.getUrl('datastorage', 'open-file')"
      target="_blank"
    >
      <input type="hidden" name="data_storage_id" :value="file[fileIdKey]" />
    </form>

    <button type="submit" class="btn-link" @click="submitForms">
      <template v-if="$slots.default">
        <slot />
      </template>
      <template v-else>Otevřít</template>
    </button> -->

    <template v-for="(file, index) in files">
      <div
        :key="file[fileIdKey] + '-' + index"
        :id="file[fileIdKey] + '-' + index"
        class="d-flex align-items-center my-2"
      >
        <form
          :ref="'base-file-opener-' + file[fileIdKey]"
          method="post"
          :action="
            $fnc.getUrl('datastorage', 'open-file', {
              id: file[fileIdKey]
            })
          "
          target="_blank"
        />

        <div
          :id="'label-' + file[fileIdKey]"
          class="break-all"
          v-if="!$slots.default"
        >
          {{
            file.original_file_name
              ? file.original_file_name
              : file.generated_file_name
          }}
        </div>

        <button
          type="submit"
          class="btn-link"
          @click.prevent="submitForm(file[fileIdKey])"
        >
          <template v-if="$slots.default">
            <slot />
          </template>
          <template v-else>
            <feather-icon
              icon="ExternalLinkIcon"
              class="w-4 h-4 d-flex my-auto ml-2"
              title="Otevřít soubor"
            />
            <!-- Otevřít -->
          </template>
        </button>
      </div>

      <hr class="my-0" v-if="index + 1 < files.length" :key="'hr-' + index" />
    </template>
  </div>

  <div v-else class="text-sm text-muted text-center">Bez přílohy</div>
</template>

<script>
export default {
  name: 'BaseFileOpener',

  props: {
    files: {
      type: Array,
      default: () => []
    },
    fileIdKey: {
      type: String,
      default: 'id'
    }
  },

  computed: {
    fileIds() {
      let temp = [];

      if (!this.$fnc.isArrayEmpty(this.files)) {
        this.files.forEach((i) => {
          temp.push(i[this.fileIdKey]);
        });
      }

      return temp;
    }
  },

  methods: {
    // open file
    async submitForm(file_id) {
      const form = this.$refs['base-file-opener-' + file_id][0]; //retrieve the form as a DOM element

      var input = document.createElement('input'); //prepare a new input DOM element
      input.setAttribute('name', 'access-token'); //set the param name
      input.setAttribute('value', this.$auth.getAccessToken()); //set the value
      input.setAttribute('type', 'hidden'); //set the type, like "hidden" or other

      form.appendChild(input); //append the input to the form

      await form.submit(); //send with added input

      form.removeChild(input); // remove input
    }

    /* Old approach: // open all files
    submitForms() {
      this.files.forEach(async (item) => {
        const form = this.$refs['base-file-opener-' + item[this.fileIdKey]][0]; //retrieve the form as a DOM element

        var input = document.createElement('input'); //prepare a new input DOM element
        input.setAttribute('name', 'access-token'); //set the param name
        input.setAttribute('value', this.$auth.getAccessToken()); //set the value
        input.setAttribute('type', 'hidden'); //set the type, like "hidden" or other

        form.appendChild(input); //append the input to the form

        await form.submit(); //send with added input

        form.removeChild(input); // remove input
      });
    } */
  }
};
</script>

<style scoped>
.btn-link {
  background: none;
  border: none;
}
.btn-link:hover {
  text-decoration: underline;
}
</style>
