/*=========================================================================================
  File Name: firms.js
  Description: actions requests to firms.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'firms';

const actions = {
  list: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'list', payload);

    commit('set', ['masterdata', 'firms', data.data['firms']], { root: true });

    return data;
  },

  new: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'new');

    commit('set', ['masterdata', 'pricelists', data.data['pricelists']], {
      root: true
    });
    commit('set', ['masterdata', 'rangetypes', data.data['rangetypes']], {
      root: true
    });

    return data;
  },

  detail: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'detail', payload);

    commit('set', ['masterdata', 'pricelists', data.data['pricelists']], {
      root: true
    });
    commit('set', ['masterdata', 'rangetypes', data.data['rangetypes']], {
      root: true
    });

    return data;
  },

  'firms-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'firms-save', payload);

    return data;
  },

  'firms-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'firms-remove', payload);

    return data;
  },

  firmpersons: async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'firmpersons', payload);

    return data;
  },

  'firmpersons-save': async (context, payload) => {
    await Vue.$api.update(moduleName, 'firmpersons-save', payload);
  },

  'firmpersons-remove': async (context, payload) => {
    await Vue.$api.delete(moduleName, 'firmpersons-remove', payload);
  },

  'firms-brands-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'firms-brands-save',
      payload
    );

    return data.saved;
  },

  'firms-brands-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'firms-brands-remove',
      payload
    );

    return data.success;
  }
};

export default {
  namespaced: true,
  actions
};
