<template>
  <base-modal :active.sync="activeLocal" title="Editace Události" size="xs">
    <div class="calendar__label-container flex">
      <vs-chip :class="'bg-' + getChosenLabel.color" class="text-white">
        {{ getChosenLabel.text }}
      </vs-chip>
    </div>

    <div @keyup.enter.stop="editEvent">
      <small class="date-label">Název události</small>
      <vs-input v-model="item.name" name="event-name" class="w-full" disabled />

      <div class="my-4" v-if="item.label == 'release'">
        <small class="date-label">Datum</small>
        <base-datepicker
          :value.sync="item.date_from"
          :clearable="false"
          @update:value="onReleaseDateChange"
        />
      </div>

      <div class="my-4">
        <small class="date-label">Začátek</small>
        <base-datepicker
          v-if="item.label == 'release'"
          type="time"
          :value.sync="item.date_from"
          placeholder="-- vyberte čas --"
          :dateForTime="item.date_from"
          :clearable="false"
        />
        <base-datepicker
          v-else
          :value.sync="item.date_from"
          :clearable="false"
          @update:value="onStartDateChange"
        />
      </div>

      <div class="my-4">
        <small class="date-label">Konec</small>
        <base-datepicker
          v-if="item.label == 'release'"
          type="time"
          :value.sync="item.date_to"
          placeholder="-- vyberte čas --"
          :dateForTime="item.date_from"
          :clearable="false"
        />
        <base-datepicker
          v-else
          :value.sync="item.date_to"
          :disabled="item.days == '0.5'"
          :clearable="false"
        />
      </div>

      <div class="my-4" v-if="item.label != 'release'">
        <small class="date-label">Půlden</small>
        <vs-checkbox
          :value="item.days == 0.5"
          vs-value="0.5"
          @change="onDaysChange"
        />
      </div>

      <small class="date-label">Poznámka</small>
      <vs-input v-model="item.note" name="event-note" class="w-full" disabled />
    </div>

    <hr />
    <base-button
      class="d-flex ml-auto mt-4"
      :disabled="!validForm"
      @click="editEvent"
      >Uložit</base-button
    >
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EventModal',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['HOLIDAY_LABELS']),

    validForm() {
      return (
        // Hide for now: this.title != '' &&
        this.item.date_from != '' &&
        this.item.date_to != '' &&
        Date.parse(this.item.date_to) - Date.parse(this.item.date_from) >= 0
      );
    },

    getChosenLabel() {
      const chosenLabel = this.$fnc.arrayFind(
        this.HOLIDAY_LABELS,
        'value',
        this.item.label
      );
      if (chosenLabel) {
        return chosenLabel;
      }

      return {};
    },

    activeLocal: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit('update:active', val);
      }
    }
  },

  methods: {
    async editEvent() {
      if (!this.validForm) return;

      const isSaved = await this.$store.dispatch(
        'user/holiday-save',
        this.item
      );

      if (isSaved) {
        this.$emit('fetch');
        this.activeLocal = false;
      } else {
        this.activeLocal = true;
      }
    },

    onDaysChange(val) {
      if (this.item.days == val) {
        this.item.days = '';
      } else {
        this.item.days = 0.5;
        this.item.date_to = this.item.date_from;
      }
    },

    onReleaseDateChange(val) {
      const splitFrom = this.item.date_from.split(' ');
      const splitTo = this.item.date_to.split(' ');

      let timeFrom = '00:00:00';
      let timeTo = '00:00:00';
      if (splitFrom.length > 1) {
        timeFrom = splitFrom[1];
      }
      if (splitTo.length > 1) {
        timeTo = splitTo[1];
      }

      this.item.date_from = val + ' ' + timeFrom;
      this.item.date_to = val + ' ' + timeTo;
    },

    onStartDateChange(val) {
      if (this.item.days == '0.5') {
        this.item.date_to = val;
      }
    }
  }
};
</script>
