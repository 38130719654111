<template>
  <div
    v-if="isActive"
    :class="['alert-' + color]"
    class="alert alert-dismissible d-flex align-items-center w-full"
    style="margin-bottom: 0.5rem"
  >
    <svg
      class="icon icon-custom-size flex-shrink-0 mr-2 flex-shrink-0"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      role="img"
    >
      undefined
      <rect
        width="34.924"
        height="34.924"
        x="256"
        y="95.998"
        fill="var(--ci-primary-color, currentColor)"
        class="ci-primary"
      ></rect>
      <path
        fill="var(--ci-primary-color, currentColor)"
        d="M16,496H496V16H16ZM48,48H464V464H48Z"
        class="ci-primary"
      ></path>
      <path
        fill="var(--ci-primary-color, currentColor)"
        d="M285.313,359.032a18.123,18.123,0,0,1-15.6,8.966,18.061,18.061,0,0,1-17.327-23.157l35.67-121.277A49.577,49.577,0,0,0,194.7,190.572l-11.718,28.234,29.557,12.266,11.718-28.235a17.577,17.577,0,0,1,33.1,11.7l-35.67,121.277A50.061,50.061,0,0,0,269.709,400a50.227,50.227,0,0,0,43.25-24.853l15.1-25.913-27.646-16.115Z"
        class="ci-primary"
      ></path>
    </svg>

    <slot />

    <feather-icon
      v-if="dismissible"
      icon="XIcon"
      svg-classes="h-5 w-5"
      class="cursor-pointer ml-auto"
      style="margin-right: -40px"
      @click="isActive = false"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',

  props: {
    dismissible: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'warning'
    }
  },

  data() {
    return {
      isActive: true
    };
  }
};
</script>
