<template>
  <div @keyup.enter.stop>
    <el-input
      ref="base-textarea"
      v-model="inputValue"
      :name="name"
      type="textarea"
      :autosize="autosize"
      :size="size"
      :placeholder="placeholder"
      :rows="rows"
      :disabled="disabled"
      :class="{ 'is-changed': isChanged }"
      @focus="(e) => $emit('focus', e)"
    />
  </div>
</template>

<script>
export default {
  name: 'base-textarea',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    isChanged: {
      type: Boolean,
      default: false
    },
    autosize: {
      type: Boolean,
      default: true
    },
    rows: {
      type: [Number, String],
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('before-update', val);
        this.$emit('update:value', val);
      }
    }
  },

  methods: {
    focusManually() {
      this.$refs['base-textarea'].focus();
    }
  }
};
</script>

<style lang="scss">
.is-changed .el-textarea__inner {
  border: 1px solid #ff9f43 !important;
}
</style>
