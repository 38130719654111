/*=========================================================================================
  File Name: pricelists.js
  Description: actions requests to pricelists.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'pricelists';

const state = {
  pricelist2Pricetypes: []
};

const getters = {
  PRICELIST2_PRICETYPES: (state) => {
    return state.pricelist2Pricetypes;
  }
};

const mutations = {
  FETCH_PRICELIST2_PRICETYPES(state, payload) {
    state.pricelist2Pricetypes = payload;
  }
};

const actions = {
  list: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'list');

    commit('set', ['masterdata', 'pricelists', data.data['pricelists']], {
      root: true
    });

    return data;
  },

  new: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'new');

    commit('set', ['masterdata', 'currencies', data.data['currencies']], {
      root: true
    });
    commit('set', ['masterdata', 'languages', data.data['languages']], {
      root: true
    });

    return data;
  },

  detail: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'detail', payload);

    commit('set', ['masterdata', 'currencies', data.data['currencies']], {
      root: true
    });
    commit('set', ['masterdata', 'languages', data.data['languages']], {
      root: true
    });

    return data;
  },

  'pricelists-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'pricelists-save', payload);

    return data;
  },

  'pricelists-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'pricelists-remove',
      payload
    );

    return data;
  },

  'pricelists-duplicate': async (context, payload) => {
    const data = await Vue.$api.post(
      moduleName,
      'pricelists-duplicate',
      payload
    );

    return data;
  },

  'items-all': async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'items-all', payload);

    commit('set', ['masterdata', 'jobtypes', data['jobtypes']], {
      root: true
    });
    commit('set', ['masterdata', 'units', data['units']], { root: true });

    return data;
  },

  /* TODO: smazat */
  items: async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'items', payload);

    commit('set', ['masterdata', 'jobtypes', data['jobtypes']], {
      root: true
    });
    commit('set', ['masterdata', 'units', data['units']], { root: true });

    return data;
  },

  'pricelists2-form': async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'pricelists2-form', payload);

    commit('FETCH_PRICELIST2_PRICETYPES', data['pricetypes']);

    return data;
  },

  'pricelists2-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'pricelists2-save', payload);

    return data;
  },

  'pricelists2-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'pricelists2-remove',
      payload
    );

    return data;
  },

  'pricelists3-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'pricelists3-save', payload);

    return data;
  },

  'pricelists3-update': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'pricelists3-update',
      payload
    );

    return data;
  },

  'pricelists3-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'pricelists3-remove',
      payload
    );

    return data;
  },

  'pricelists3link-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'pricelists3link-save',
      payload
    );

    return data;
  },

  'pricelists3link-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'pricelists3link-remove',
      payload
    );

    return data;
  },

  'pricelists3jobtypelink-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'pricelists3jobtypelink-save',
      payload
    );

    return data;
  },

  'pricelists3jobtypelink-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'pricelists3jobtypelink-remove',
      payload
    );

    return data;
  },

  'pricetypes-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'pricetypes-save', payload);

    return data;
  },

  'pricelists2-notes-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'pricelists2-notes-save',
      payload
    );

    return data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
