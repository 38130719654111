/*=========================================================================================
  File Name: RouteSchema.js
  Description: Schema for routes with tabs
==========================================================================================*/
import { getRoute } from './Routering.js';

export default class RouteSchema {
  module;
  moduleUpperCase;
  route;
  fileDir;
  admin;
  developer;
  componentIndex;
  componentList;
  componentNew;
  componentDetail;

  constructor(module, admin = false, developer = false) {
    // set module name
    this.module = module;
    // set if admin privilege (group_id = 5) is required
    this.admin = admin;
    // set if developer = 'Y' is required
    this.developer = developer;

    // set route path
    this.route = getRoute(this.module);

    // set module name upper case after underscore (for components name)
    let componentName = '';
    this.module.split('_').forEach((part) => {
      componentName += part.charAt(0).toUpperCase() + part.slice(1);
    });
    this.moduleUpperCase = componentName;

    /* // set module name upper case (for components name)
    let first = this.module.split('_')[0];
    let second = this.module.split('_')[1];
    this.moduleUpperCase =
      first.charAt(0).toUpperCase() +
      first.slice(1) +
      (second ? second.charAt(0).toUpperCase() + second.slice(1) : ''); */

    // replace route dash with underscore (for components path)
    this.fileDir = this.route.path.split('-').join('_');
    // add slash if missing at the end
    if (this.fileDir.charAt(this.fileDir.length - 1) != '/') {
      this.fileDir += '/';
    }

    // Load section components
    this.componentIndex = () =>
      import('@/views' + this.fileDir + this.moduleUpperCase + '.vue');
    //  for tabs sections
    this.componentList = () =>
      import('@/views' + this.fileDir + this.moduleUpperCase + 'List.vue');
    this.componentNew = () =>
      import('@/views' + this.fileDir + this.moduleUpperCase + 'New.vue');
    this.componentDetail = () =>
      import('@/views' + this.fileDir + this.moduleUpperCase + 'Detail.vue');
  }

  // basic route schema
  getBasicRoutes() {
    return [
      {
        ...this.route,
        component: this.componentIndex,
        meta: {
          translation_key: this.route.translation_key,
          path_key: this.module,
          admin: this.admin,
          developer: this.developer,
          auth: true,
          overlay: true,
          main_section: true
        }
      }
    ];
  }

  // route schema for section with tabs
  getTabsRoutes() {
    return [
      {
        path: this.route.path,
        component: this.componentIndex,
        meta: {
          translation_key: this.route.translation_key,
          path_key: this.module,
          admin: this.admin,
          developer: this.developer,
          auth: true
        },
        children: [
          {
            ...this.route,
            component: this.componentList,
            meta: {
              translation_key: this.route.translation_key,
              path_key: this.module,
              tab: 'list',
              admin: this.admin,
              developer: this.developer,
              auth: true,
              overlay: true,
              main_section: true
            }
          },
          {
            ...getRoute(this.module, 'new'),
            component: this.componentNew,
            meta: {
              translation_key: this.route.translation_key,
              path_key: this.module,
              tab: 'new',
              admin: this.admin,
              developer: this.developer,
              auth: true,
              overlay: true,
              main_section: true
            }
          },
          {
            ...getRoute(this.module, 'detail'),
            component: this.componentDetail,
            meta: {
              translation_key: this.route.translation_key,
              path_key: this.module,
              admin: this.admin,
              developer: this.developer,
              auth: true,
              overlay: true,
              main_section: true,
              section: 'detail'
            }
          }
        ]
      }
    ];
  }
}
