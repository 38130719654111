<!--=========================================================================================
  File Name: BaseButton.vue
  Description: Reusable button component
==========================================================================================-->
<template>
  <vs-button
    v-tooltip.right="tooltip"
    :size="size"
    :color="colorLocal"
    icon-pack="feather"
    :icon="iconLocal"
    :type="type"
    :disabled="disabled"
    :class="[
      'shadow-none',
      addClasses,
      {
        btn: type === 'line',
        'border-0': type === 'line',
        'px-3': !icon,
        'px-0': icon && !$slots.default
      }
    ]"
    :to="to"
    @click.stop.native="buttonClick"
    ><!-- , 'border-' + color -->
    <slot />
  </vs-button>
</template>

<script>
export default {
  name: 'base-button',

  props: {
    size: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'filled'
    },
    color: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    addClasses: {
      type: [String, Object, Array],
      default: ''
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      //clickedButton: !this.options.find(item => item.value == '') ? this.options[0].value : ''
    };
  },

  computed: {
    colorLocal() {
      switch (this.color) {
        case 'info':
          return '#39f';
        default:
          return this.color;
      }
    },

    iconLocal() {
      if (this.icon) {
        return 'icon-' + this.icon;
      }

      return '';
    }
  },

  methods: {
    buttonClick(val) {
      if (this.disabled) return;

      // this.clickedButton = val
      this.$emit('click', val);
    }
  }
};
</script>

<style>
.vs-button.small {
  font-size: 0.8rem;
}
</style>
