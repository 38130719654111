/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue';
import VxCard from './components/vx-card/VxCard.vue';
// import VxList  from './components/vx-list/VxList.vue'
import VxBreadcrumb from './layouts/components/VxBreadcrumb.vue';
import FeatherIcon from './components/FeatherIcon.vue';
import VxInputGroup from './components/vx-input-group/VxInputGroup.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import './components/base/formValidation';

Vue.component(VxTooltip.name, VxTooltip);
Vue.component(VxCard.name, VxCard);
// Vue.component(VxList.name, VxList)
Vue.component(VxBreadcrumb.name, VxBreadcrumb);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(VxInputGroup.name, VxInputGroup);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

// Load app base components dynamically (from components/base dir)
const requireContext = require.context('./components/base', false, /.*\.vue$/);

requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)])
  .reduce((components, [name, component]) => {
    let componentOutput = component['default'];

    if (componentOutput['name'] === undefined) {
      componentOutput['name'] = name;
    }

    Vue.component(componentOutput['name'], componentOutput);
  }, {});

// v-select component
import vSelect from 'vue-select';

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement =>
      createElement('feather-icon', {
        props: {
          icon: 'XIcon',
          svgClasses: 'w-4 h-4' // mt-1 ??
        }
      })
  },
  OpenIndicator: {
    render: createElement =>
      createElement('feather-icon', {
        props: {
          icon: 'ChevronDownIcon',
          svgClasses: 'w-5 h-5'
        }
      })
  }
});

Vue.component('v-select', vSelect);

/* GLOBAL DIRECTIVES */
// Register a global custom directive called `v-focus-basic`
Vue.directive('focus-basic', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the input
    el.focus()
  }
})

// Register a global custom directive called `v-focus`
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function(el, binding) {
    // Focus the vuesax input
    //  newly if param is passed and its true e.g. v-focus="focus"
    if (binding.name === 'focus' && binding.value) {
      el.getElementsByTagName('input')[0].focus();
    }
  }
});