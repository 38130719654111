<template>
  <div id="simple-calendar-app">
    <div class="vx-card no-scroll-content">
      <calendar-view
        ref="calendar"
        locale="cs-CZ"
        :starting-day-of-week="1"
        :display-period-uom="calendarView"
        :show-date="showDate"
        :events="calendarEvents"
        :event-top="windowWidth <= 400 ? '2rem' : '3rem'"
        enable-drag-drop
        event-border-height="0px"
        event-content-height="1.65rem"
        class="theme-default holiday-cz-traditional holiday-cz-official"
        @click-date="openAddNewEvent"
        @click-event="openEditEvent"
        @drop-on-date="eventDragged"
      >
        <!-- class="theme-default holiday-us-traditional holiday-us-official" -->
        <div slot="header" class="mb-4">
          <div class="vx-row no-gutter">
            <!-- Month Name -->
            <div class="vx-col w-1/3 sm:block hidden">
              <!-- Add new event button -->
              <base-button icon="plus" @click="promptAddNewEvent(new Date())">
                Přidat
              </base-button>
            </div>

            <!-- Current Month -->
            <div
              class="vx-col lg:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last"
            >
              <feather-icon
                v-tooltip.right="'Dnes'"
                icon="CalendarIcon"
                class="cursor-pointer sm:mr-3 text-primary"
                @click="showDate = new Date()"
              />
              <div class="flex items-center">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  svg-classes="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                  @click="updateDate(-1)"
                />

                <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{
                  getDateName
                }}</span>

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  svg-classes="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                  @click="updateDate(1)"
                />
              </div>
            </div>

            <div class="vx-col lg:w-1/3 w-full flex justify-center">
              <template v-for="(view, index) in calendarViewTypes">
                <base-button
                  v-if="calendarView === view.val"
                  :key="String(view.val) + 'filled'"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1
                  }"
                  type="filled"
                  class="p-3 md:px-8 md:py-3"
                  @click="calendarView = view.val"
                >
                  {{ view.label }}
                </base-button>
                <base-button
                  v-else
                  :key="String(view.val) + 'border'"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1
                  }"
                  type="border"
                  class="p-3 md:px-8 md:py-3"
                  @click="calendarView = view.val"
                >
                  {{ view.label }}
                </base-button>
              </template>
            </div>
          </div>

          <div class="vx-row sm:flex hidden mt-4">
            <div class="vx-col w-full flex">
              <!-- Labels -->
              <div class="flex flex-wrap sm:justify-start justify-center">
                <!-- <div class="flex items-center mr-4 mb-2">
                  <div
                    class="h-3 w-3 inline-block rounded-full mr-2 bg-primary"
                  />
                  <span>Dnes</span>
                </div> -->
                <div
                  v-for="(label, index) in calendarLabelsDescription"
                  :key="index"
                  class="flex items-center mr-4 mb-2"
                >
                  <div
                    :class="'bg-' + label.color"
                    class="h-3 w-3 inline-block rounded-full mr-2"
                  />
                  <span>{{ label.text }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </calendar-view>
    </div>

    <!-- ADD EVENT -->
    <vs-prompt
      :is-valid="validForm"
      :active.sync="activePromptAddEvent"
      class="calendar-event-dialog"
      title="Přidat událost"
      accept-text="Uložit"
      cancel-text="Zrušit"
      @accept="addEvent"
    >
      <div class="calendar__label-container flex">
        <vs-chip
          v-if="labelLocal != 'none'"
          :class="'bg-' + getChosenLabel.color"
          class="text-white"
        >
          {{ getChosenLabel.text }}
        </vs-chip>

        <vs-dropdown
          vs-custom-content
          vs-trigger-click
          class="ml-auto my-2 cursor-pointer"
        >
          <feather-icon
            icon="TagIcon"
            svg-classes="h-5 w-5"
            class="cursor-pointer"
            @click.prevent
          />

          <vs-dropdown-menu style="z-index: 200001">
            <vs-dropdown-item
              v-for="(label, index) in calendarLabels"
              :key="index"
              class="w-100"
              @click="toggleLabel(label.value)"
            >
              <div
                :class="'bg-' + label.color"
                class="h-3 w-3 inline-block rounded-full mr-2"
              />
              <span>{{ label.text }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <div @keyup.enter.stop="addEvent">
        <small class="date-label">Název události</small>
        <vs-input v-model="title" name="event-name" class="w-full" />
        <!--  v-validate="'required'" -->
        <div class="my-4" v-if="labelLocal == 'release'">
          <small class="date-label">Datum</small>
          <base-datepicker
            :value.sync="startDateCache"
            :disabled="disabledFrom"
            :clearable="false"
            @update:value="onReleaseDateChange"
          />
          <!-- <div class="my-2">
            {{ $fnc.dateToCz(startDateCache, { no_time: true }) }}
          </div> -->
        </div>
        <div class="my-4">
          <small class="date-label">Začátek</small>
          <base-datepicker
            v-if="labelLocal == 'release'"
            type="time"
            :value.sync="startDate"
            placeholder="-- vyberte čas --"
            :dateForTime="startDateCache"
            :clearable="false"
          />
          <base-datepicker
            v-else
            :value.sync="startDate"
            :disabled="labelLocal == 'release' ? false : disabledFrom"
            :clearable="false"
            @update:value="onStartDateChange"
          />
          <!-- <datepicker 
            v-model="startDate" 
            :language="$vs.rtl ? langEn : langCz" 
            :disabled="disabledFrom" 
            monday-first 
            name="start-date"
          /> -->
        </div>
        <div class="my-4">
          <small class="date-label">Konec</small>
          <base-datepicker
            v-if="labelLocal == 'release'"
            type="time"
            :value.sync="endDate"
            placeholder="-- vyberte čas --"
            :dateForTime="startDateCache"
            :clearable="false"
          />
          <base-datepicker
            v-else
            :value.sync="endDate"
            :disabled="disabledTo"
            :clearable="false"
          />
          <!-- <datepicker 
            v-model="endDate" 
            :language="$vs.rtl ? langEn : langCz" 
            :disabled-dates="disabledDatesTo" 
            monday-first 
            name="end-date"
          /> -->
        </div>

        <div class="my-4" v-if="labelLocal != 'release'">
          <small class="date-label">Půlden</small>
          <vs-checkbox v-model="halfday" vs-value="0.5" />
        </div>

        <small class="date-label">Poznámka</small>
        <vs-input v-model="note" name="event-note" class="w-full" />
        <!--  v-validate="'note'" :color="!errors.has('event-note') ? 'success' : 'danger'" -->
      </div>
    </vs-prompt>

    <!-- EDIT EVENT -->
    <vs-prompt
      :is-valid="validForm"
      :active.sync="activePromptEditEvent"
      class="calendar-event-dialog"
      title="Editace Události"
      accept-text="Uložit"
      cancel-text="Odebrat"
      button-cancel="border"
      @cancel="removeEvent"
      @accept="editEvent"
    >
      <div class="calendar__label-container flex">
        <vs-chip
          v-if="labelLocal != 'none'"
          :class="'bg-' + getChosenLabel.color"
          class="text-white"
        >
          {{ getChosenLabel.text }}
        </vs-chip>

        <!-- Hide for now: <vs-dropdown vs-custom-content class="ml-auto my-2 cursor-pointer">
          <feather-icon icon="TagIcon" svg-classes="h-5 w-5" @click.prevent />

          <vs-dropdown-menu style="z-index: 200001">
            <vs-dropdown-item
              v-for="(label, index) in calendarLabels"
              :key="index"
              class="w-100"
              @click="toggleLabel(label.value)"
            >
              <div
                :class="'bg-' + label.color"
                class="h-3 w-3 inline-block rounded-full mr-2"
              />
              <span>{{ label.text }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown> -->
      </div>

      <div @keyup.enter.stop="editEvent">
        <small class="date-label">Název události</small>
        <vs-input v-model="title" name="event-name" class="w-full" />
        <!-- v-validate="'required'"  -->
        <div class="my-4" v-if="labelLocal == 'release'">
          <small class="date-label">Datum</small>
          <base-datepicker
            :value.sync="startDateCache"
            :disabled="disabledFrom"
            :clearable="false"
            @update:value="onReleaseDateChange"
          />
          <!-- <div class="my-2">
            {{ $fnc.dateToCz(startDateCache, { no_time: true }) }}
          </div> -->
        </div>
        <div class="my-4">
          <small class="date-label">Začátek</small>
          <base-datepicker
            v-if="labelLocal == 'release'"
            type="time"
            :value.sync="startDate"
            placeholder="-- vyberte čas --"
            :dateForTime="startDateCache"
            :clearable="false"
          />
          <base-datepicker
            v-else
            :value.sync="startDate"
            :disabled="labelLocal == 'release' ? false : disabledFrom"
            :clearable="false"
            @update:value="onStartDateChange"
          />
          <!-- <datepicker 
            v-model="startDate" 
            :language="$vs.rtl ? langEn : langCz" 
            :disabled-dates="disabledDatesFrom" 
            name="start-date"
          /> -->
        </div>
        <div class="my-4">
          <small class="date-label">Konec</small>
          <base-datepicker
            v-if="labelLocal == 'release'"
            type="time"
            :value.sync="endDate"
            placeholder="-- vyberte čas --"
            :dateForTime="startDateCache"
            :clearable="false"
          />
          <base-datepicker
            v-else
            :value.sync="endDate"
            :disabled="disabledTo"
            :clearable="false"
          />
          <!-- <datepicker 
            v-model="endDate" 
            :language="$vs.rtl ? langEn : langCz" 
            :disabled-dates="disabledDatesTo" 
            name="end-date"
          /> -->
        </div>
        <!-- <div class="my-4 flex justify-around text-center">
          <div>
            <small>Čas začátku</small>
            <div>
              {{
                `${$fnc.zeroPrefix(
                  new Date(startDate).getHours(),
                  2
                )}:${$fnc.zeroPrefix(
                  new Date(startDate).getMinutes(),
                  2
                )}:${$fnc.zeroPrefix(new Date(startDate).getSeconds(), 2)}`
              }}
            </div>
          </div>
          <div>
            <small>Čas konce</small>
            <div>
              {{
                `${$fnc.zeroPrefix(
                  new Date(endDate).getHours(),
                  2
                )}:${$fnc.zeroPrefix(
                  new Date(endDate).getMinutes(),
                  2
                )}:${$fnc.zeroPrefix(new Date(endDate).getSeconds(), 2)}`
              }}
            </div>
          </div>
        </div> -->

        <div class="my-4" v-if="labelLocal != 'release'">
          <small class="date-label">Půlden</small>
          <vs-checkbox v-model="halfday" vs-value="0.5" />
        </div>

        <small class="date-label">Poznámka</small>
        <vs-input v-model="note" name="event-note" class="w-full" />
        <!-- v-validate="'note'" :color="!errors.has('event-note') ? 'success' : 'danger'" -->
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { CalendarView } from 'vue-simple-calendar';
// import moduleCalendar from '@/store/calendar/moduleCalendar.js'
require('vue-simple-calendar/static/css/default.css');
require('./holidays-cz.css');
// require('vue-simple-calendar/static/css/holidays-us.css');
//import Datepicker from 'vuejs-datepicker';
//import { cs, en, he } from "vuejs-datepicker/src/locale";

import { mapGetters } from 'vuex';

export default {
  components: {
    CalendarView
    // Datepicker
  },
  data() {
    return {
      showDate: new Date(),
      disabledFrom: false,
      disabledTo: false,
      id: 0,
      title: '',
      startDate: '',
      endDate: '',
      halfday: '',
      labelLocal: 'holiday',

      startDateCache: '',
      // langHe: he,
      // langEn: en,
      // langCz: cs,

      note: '',
      calendarView: 'month',

      activePromptAddEvent: false,
      activePromptEditEvent: false,

      calendarViewTypes: [
        {
          label: 'Měsíc',
          val: 'month'
        },
        {
          label: 'Týden',
          val: 'week'
        },
        {
          label: 'Rok',
          val: 'year'
        }
      ],
      month: 6
    };
  },
  computed: {
    ...mapGetters(['USER_ID', 'HOLIDAY_LABELS']),
    calendarEvents() {
      return this.$store.state.calendar.events;
    },
    validForm() {
      return (
        // Hide for now: this.title != '' &&
        this.startDate != '' &&
        this.endDate != '' &&
        Date.parse(this.endDate) - Date.parse(this.startDate) >= 0
      ); // && !this.errors.has('event-note');
    },
    disabledDatesTo() {
      return { to: new Date(this.startDate.replace(/\s/, 'T')) };
    },
    disabledDatesFrom() {
      return { from: new Date(this.endDate.replace(/\s/, 'T')) };
    },
    calendarLabels() {
      return this.HOLIDAY_LABELS;
    },
    calendarLabelsDescription() {
      return this.$store.state.calendar.eventLabelsDescription;
    },
    /* labelColor() {
      return (label) => {
        if (label == 'holiday') return 'danger';
        else if (label == 'homeoffice') return 'warning';
        //if (label == "misc") return "danger"
        if (label == 'none') return 'none';
      };
    }, */
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    getDateName() {
      return this.toDateStr(this.showDate);
    },

    getChosenLabel() {
      const chosenLabel = this.$fnc.arrayFind(
        this.calendarLabels,
        'value',
        this.labelLocal
      );
      if (chosenLabel) {
        return chosenLabel;
      }

      return {};
    }
  },

  watch: {
    halfday(val) {
      if (val == '0.5') {
        this.disabledTo = true;
        this.endDate = this.startDate;
      } else {
        this.disabledTo = false;
      }
    }
  },

  methods: {
    toDateStr(time) {
      if (this.calendarView == 'year') {
        return time.getFullYear();
      } else if (this.calendarView == 'month') {
        const locale = 'cs-CZ';
        const date_obj = new Date(Date.parse(time));
        const monthName = date_obj.toLocaleString(locale, {
          month: 'short'
        });
        // const today = new Date().getDate()
        // if(today == date_obj.getDate() ) return "Dnes" // pokud datum je dnesek
        // else return date_obj.getDate() + ' '  + monthName;
        return monthName + ' ' + date_obj.getFullYear();
      } else {
        const firstDayOfYear = new Date(time.getFullYear(), 0, 1);
        const pastDaysOfYear = (time - firstDayOfYear) / 86400000; // ms
        return (
          Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7) +
          '. týden'
        );
      }
    },
    async addEvent() {
      const startDateT = new Date(this.startDate.replace(/\s/, 'T'));
      const endDateT = new Date(this.endDate.replace(/\s/, 'T'));

      const payload = {
        title: this.title,
        startDate: this.$fnc.dateTimeFormat(startDateT),
        endDate: this.$fnc.dateTimeFormat(endDateT),
        note: this.note,
        label: this.labelLocal,

        postData: {
          name: this.title,
          users_id: this.USER_ID,
          date_from: this.$fnc.dateTimeFormat(startDateT),
          date_to: this.$fnc.dateTimeFormat(endDateT),
          days: this.halfday
            ? this.halfday
            : this.$fnc.dateDiffInDays(startDateT, endDateT, true),
          note: this.note,
          label: this.labelLocal
        }
      };

      payload.classes = 'event-' + this.getChosenLabel.color;
      const data = await this.$store.dispatch('calendar/addEvent', payload);

      if (data.saved) {
        // feat: Real-time notifications
        const usersToApproveHoliday = data['users_to_approve_holiday'];
        if (!this.$fnc.isArrayEmpty(usersToApproveHoliday)) {
          usersToApproveHoliday.forEach((id) => {
            if (this.USER_ID == id) {
              this.$store.dispatch('app-notifications');
            } else {
              this.$socket.emit('fetchNotifications', id);
            }
          });
        }

        this.$emit('fetch-events');
        this.activePromptAddEvent = false;
      } else {
        this.activePromptAddEvent = true;
      }
    },
    updateDate(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
      //this.displayName = this.$refs.calendar.getIncrementedPeriod(val);
    },
    clearFields() {
      this.title = this.endDate = this.note = this.halfday = '';
      this.id = 0;
      this.labelLocal = this.$options.data().labelLocal;
      this.startDateCache = '';
    },
    promptAddNewEvent(date) {
      date.setHours(0, 0, 0);
      this.disabledFrom = false;
      this.disabledTo = false;
      this.addNewEventDialog(date);
    },
    addNewEventDialog(date) {
      this.clearFields();
      this.startDate = this.$fnc.dateTimeFormat(date);
      this.endDate = this.$fnc.dateTimeFormat(date);
      this.startDateCache = this.startDate;
      this.activePromptAddEvent = true;
    },
    openAddNewEvent(date) {
      // if(new Date(date) < new Date()) return
      this.disabledFrom = true;
      this.addNewEventDialog(date);
    },
    openEditEvent(event) {
      const e = this.$store.getters['calendar/getEvent'](event.id);

      let start_d = e.startDate;
      let end_d = e.endDate;
      if (this.$fnc.isAnyDateFormat(start_d)) {
        start_d = start_d.replace(/\s/, 'T');
      }
      if (this.$fnc.isAnyDateFormat(end_d)) {
        end_d = end_d.replace(/\s/, 'T');
      }
      if (
        (e.label === 'holiday' ||
          e.label === 'homeoffice' ||
          e.label === 'release') &&
        (e.approved !== 'X' ||
          this.$fnc.dateDiffInDays(new Date(), new Date(start_d)) < 0)
      ) {
        return;
      }

      this.id = e.id;
      this.title = e.name; // e.title;
      this.startDate = this.$fnc.dateTimeFormat(new Date(start_d));
      this.endDate = this.$fnc.dateTimeFormat(new Date(end_d));
      this.startDateCache = this.startDate;
      this.note = e.note;
      this.halfday = e.days == '0.5' ? e.days : '';
      this.labelLocal = e.label;
      this.activePromptEditEvent = true;
    },
    async editEvent() {
      const startDateT = new Date(this.startDate.replace(/\s/, 'T'));
      const endDateT = new Date(this.endDate.replace(/\s/, 'T'));

      const payload = {
        id: this.id,
        title: this.title,
        startDate: this.$fnc.dateTimeFormat(startDateT),
        endDate: this.$fnc.dateTimeFormat(endDateT),
        note: this.note,
        label: this.labelLocal,

        postData: {
          id: this.id,
          name: this.title,
          users_id: this.USER_ID,
          date_from: this.$fnc.dateTimeFormat(startDateT),
          date_to: this.$fnc.dateTimeFormat(endDateT),
          days: this.halfday
            ? this.halfday
            : this.$fnc.dateDiffInDays(startDateT, endDateT, true),
          note: this.note,
          label: this.labelLocal
        }
      };
      payload.classes = 'event-' + this.getChosenLabel.color;

      const isSaved = await this.$store.dispatch('calendar/editEvent', payload);

      if (isSaved) {
        this.$emit('fetch-events');
        this.activePromptEditEvent = false;
      } else {
        this.activePromptEditEvent = true;
      }
    },
    async removeEvent() {
      if (this.$fnc.confirmAction('Opravdu chcete odebrat tuto žádost?')) {
        await this.$store.dispatch('calendar/holiday-remove', {
          id: this.id
        });

        this.$emit('fetch-events');
      }
    },
    async eventDragged(event, date) {
      //return console.log(event.classes[0] === 'event-success' && new Date(date) < new Date() )
      if (
        !event ||
        event.classes[0] === 'event-success' ||
        this.$fnc.dateDiffInDays(new Date(), new Date(date)) < 0
      ) {
        return;
      }

      let start_d = event.startDate;
      let end_d = event.endDate;
      if (this.$fnc.isAnyDateFormat(start_d)) {
        start_d = start_d.replace(/\s/, 'T');
      }
      if (this.$fnc.isAnyDateFormat(end_d)) {
        end_d = end_d.replace(/\s/, 'T');
      }
      //if(date.getMonth() !== event.endDate.getMonth()) return console.log(false)
      const dateDiff = this.$fnc.dateDiffInDays(
        new Date(start_d),
        new Date(end_d)
      );
      let newDateTo = new Date(date);

      date.setHours(
        event.startDate.getHours(),
        event.startDate.getMinutes(),
        event.startDate.getSeconds()
      );
      newDateTo.setDate(date.getDate() + dateDiff);
      newDateTo.setHours(
        event.endDate.getHours(),
        event.endDate.getMinutes(),
        event.endDate.getSeconds()
      );

      let dateFrom = this.$fnc.dateTimeFormat(date); // String(date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate() + ' ' + event.startDate.getHours() + ':' + event.startDate.getMinutes() + ":" + event.startDate.getSeconds())
      let dateTo = this.$fnc.dateTimeFormat(newDateTo); // String(newDateTo.getFullYear() + '-' + (newDateTo.getMonth()+1) + '-' + newDateTo.getDate() + ' ' + event.endDate.getHours() + ':' + event.endDate.getMinutes() + ":" + event.endDate.getSeconds())
      // return console.log([dateFrom, dateTo])
      // return console.log(event, date)

      const { originalEvent = {} } = event;

      await this.$store.dispatch('calendar/eventDragged', {
        id: event.id,
        date_from: dateFrom,
        date_to: dateTo,
        name: originalEvent.name,
        days: originalEvent.days,
        note: originalEvent.note,
        label: originalEvent.label,
        users_id: this.USER_ID
      });

      this.$emit('fetch-events');
    },

    toggleLabel(label) {
      this.labelLocal = label;

      if (this.labelLocal == 'release') {
        if (this.disabledFrom) {
          this.startDate = this.$fnc.dateTimeFormat(
            new Date(this.startDateCache),
            { has_default_time: true }
          );
        } else {
          this.startDate = this.$fnc.dateTimeFormat(new Date(this.startDate), {
            has_default_time: true
          });
        }

        this.endDate = this.startDate;
        this.startDateCache = this.startDate;
        this.halfday = '';
      }

      this.startDate = this.$fnc.dateTimeFormat(new Date(this.startDate), {
        has_default_time: true
      });
      this.endDate = this.$fnc.dateTimeFormat(new Date(this.endDate), {
        has_default_time: true
      });
    },

    onStartDateChange(val) {
      if (this.halfday == '0.5') {
        this.endDate = val;
      }
    },

    onReleaseDateChange(val) {
      const splitFrom = this.startDate.split(' ');
      const splitTo = this.endDate.split(' ');

      let timeFrom = '00:00:00';
      let timeTo = '00:00:00';
      if (splitFrom.length > 1) {
        timeFrom = splitFrom[1];
      }
      if (splitTo.length > 1) {
        timeTo = splitTo[1];
      }

      this.startDate = val + ' ' + timeFrom;
      this.endDate = val + ' ' + timeTo;
    }
  }
  /* created() {
    if(!this.$store.hasModule('calendar')) this.$store.registerModule('calendar', moduleCalendar)
    this.$store.dispatch("calendar/fetchEvents", this.USER_ID)
    //this.$store.dispatch("calendar/fetchEventLabels")
  }, 
  beforeDestroy() {
    this.$store.unregisterModule('calendar')
  }*/
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/apps/simple-calendar.scss';

.today .cv-day-number {
  background-color: rgb(
    242,
    203,
    119
  ) !important; /* Change this to the desired color */
  color: white; /* Change this to the desired text color */
}
</style>
