/*=========================================================================================
  File Name: login.js
  Description: actions requests to login.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'login';

const actions = {
  submit: async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'submit', payload);

    if (Vue.$auth.searchInStatusList(data.status_list, 'ok')) {
      Vue.$auth.setAccessToken(data.data);
      Vue.$router.push(Vue.$getRoute('main_page')).catch(() => {});
      // Vue.$fnc.notificationAlert(data.message, 'success');

      return true;
    }

    return false;
  },

  logout: async ({ rootState }) => {
    const data = await Vue.$api.index(moduleName, 'logout', {
      user_id: rootState.AppActiveUser.id
    });

    if (Vue.$auth.searchInStatusList(data.status_list, 'ok')) {
      // await Vue.$store.reset();
      // Vue.$store.commit('RESET_USER_DATA');
      Vue.$auth.deleteTokenAndReloadPage();
      // Vue.$router.push(Vue.$getRoute('login')).catch(() => {});

      // Vue.$fnc.notificationAlert(data.message, 'success');

      return true;
    }

    return false;
  }
};

export default {
  namespaced: true,
  actions
};
