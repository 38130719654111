<template>
  <div>
    <vs-upload
      ref="file"
      :action="getActionUrl"
      :data="postData"
      :accept="EXTENTIONS_ALLOWED"
      :show-upload-button="showUploadButton"
      v-bind:[attributeDisabled]="disabled"
      file-name="files[]"
      multiple
      :text="text"
      :limit="limit"
      :class="[size, { 'full-width': fullWidth }]"
      @on-delete="removeFile"
      @on-success="successUpload"
    />
    <!-- {{filesToUpload}} -- {{showUploadButton}} 
      class="border"-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseUpload',

  props: {
    module: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    post: {
      type: [Object],
      default: () => {}
    },
    showUploadButton: {
      // toto nepouzivat.. CORS..
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'Nahrát soubory'
    },
    limit: {
      type: [String, Number]
    }
  },

  data() {
    return {
      filesToUpload: []
    };
  },

  computed: {
    ...mapGetters(['EXTENTIONS_ALLOWED', 'MAX_FIZE_SIZE']),

    attributeDisabled() {
      return this.disabled ? 'disabled' : null;
    },

    getActionUrl() {
      if (this.module && this.action) {
        return this.$fnc.getUrl(this.module, this.action);
      }

      return '#';
    },

    postData() {
      if (this.post && Object.keys(this.post).length > 0) {
        return {
          ...this.post,
          'access-token': this.$auth.getAccessToken()
        };
      }

      return {};
    }
  },

  watch: {
    filesToUpload(val) {
      // TODO: kontrola velikosti souboru
      val.forEach((file) => {
        if (file.size > this.MAX_FIZE_SIZE) {
          this.$fnc.notificationAlert(
            'Maximální velikost souboru je: ' +
              this.MAX_FIZE_SIZE +
              '. Soubor ' +
              file.name +
              ' se neuloží.'
          );
          file.remove = true;
        }
      });

      //console.log([this.$refs.file.filesx, val])
      this.$emit('files-to-upload', val);
    }

    /* '$refs.file.filesx'(val) {
      let temp = []
      if(this.$refs["file"]) {
          for(let i = 0; i < this.$refs["file"].filesx.length; i++) {
              if(!this.$refs["file"].filesx[i]["remove"]) {
                  temp.push(this.$refs["file"].filesx[i])
              }
          }
          this.filesToUpload = temp
      console.log(this.$refs["file"])
      }
    } */
  },

  mounted() {
    this.$watch(
      () => {
        return this.$refs.file.filesx;
      },
      (val) => {
        this.filesToUpload = val.filter((item) => !item.remove);
        /* this.filesToUpload = this.$fnc.arrayUnique(
          this.filesToUpload,
          'lastModified'
        ); */

        /* let temp = this.$fnc.arrayDuplicities(
          this.filesToUpload,
          'lastModified'
        );
        temp.forEach(item => {
          console.log([this.$refs['file'].getFilesFilter])
          this.$refs['file'].removeFile(
            this.filesToUpload.findIndex(i => i.lastModified == item && !i.remove)+1
          );
        }); */

        /* this.$refs['file'].filesx = this.$fnc.arrayUnique(
          this.$refs['file'].filesx
        ); */
        //alert('App $watch $refs.counter.i: ' + val)
      }
    );
  },

  methods: {
    // TODO: radsi to pak jeste projit..
    addFiles(files) {
      files.forEach((file) => {
        // console.log(file)
        this.$refs['file'].filesx.push(file);
        this.$refs['file'].srcs.push({
          error: false,
          name: file.name,
          percent: null,
          remove: null,
          src: null,
          type: file.type
        });
      });
      // console.log(this.$refs['file']);
    },

    // removes all files
    removeAllFiles() {
      this.$refs['file'].getFilesFilter.forEach((item, i) => {
        this.$refs['file'].removeFile(i);
      });
      this.filesToUpload = [];

      /* this.$refs.file.filesx.forEach((file) => {
        file.remove = true;
      });

      this.$refs.file.filesx = this.$refs.file.filesx.filter(
        (item) => !item.remove
      ); */
    },

    removeFile(removedFile) {
      setTimeout(() => {
        // this.$refs.file.filesx
        this.filesToUpload = this.filesToUpload.filter((item) => !item.remove);
      }, 500);
    },

    successUpload(e) {
      // console.log(this.$refs['file']);
      try {
        const response = JSON.parse(e.target.response);

        this.$emit('success-upload', response);
      } catch (e) {
        this.$fnc.notificationAlert(undefined, 'error', 5000);
      }

      this.$refs['file'].getFilesFilter.forEach((item, i) => {
        if (this.$refs['file'].getFilesFilter[i].percent) {
          this.$refs['file'].removeFile(i);
        }
      });

      /* if(this.$refs["file"]) {
          this.$refs["file"].filesx.forEach((item, i) => {
              this.$refs["file"].removeFile(i)
          })
      } */
    }
  }
};
</script>

<style lang="scss">
.sm {
  .con-input-upload,
  .con-img-upload .img-upload {
    margin-left: 0px !important;
    margin-top: 0px !important;
    width: 114px;
    height: 114px;
  }
}
.md {
  .con-input-upload,
  .con-img-upload .img-upload {
    margin-left: 0px !important;
    margin-top: 0px !important;
    width: 131px;
    height: 131px;
  }
}
.lg {
  .con-input-upload,
  .con-img-upload .img-upload {
    margin-left: 0px !important;
    margin-top: 0px !important;
    width: 153px;
    height: 153px;
  }
}

.full-width div.con-input-upload {
  width: 100%;
}
</style>
