/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';
/* import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie'; */

// DEFAULT
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

// MODULES
/* //  main app
import admin from './modules/admin';
import datastorage from './modules/datastorage';
import estimates from './modules/estimates';
import firms from './modules/firms';
import login from './modules/login';
import masterdata from './modules/masterdata';
import orders from './modules/orders';
import pricelists from './modules/pricelists';
import user from './modules/user';
import workflow from './modules/workflow'; */

// Load store modules dynamically
const requireContext = require.context('./modules', false, /.*\.js$/);

const modules = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    // Set all modules namespaced to true
    /* if (module.namespaced === undefined) {
      module.namespaced = true;
    } */

    return { ...modules, [name]: module['default'] };
  }, {});

Vue.use(Vuex);

export default createStore(Vuex.Store, {
  state,
  mutations,
  actions,
  getters,

  modules,
  /* modules: {
    admin,
    datastorage,
    estimates,
    firms,
    login,
    masterdata,
    orders,
    pricelists,
    user,
    workflow
  }, */
  strict: process.env.NODE_ENV !== 'production'
  /* plugins: [createPersistedState({
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
      removeItem: key => Cookies.remove(key)
    }
  })], */
});
